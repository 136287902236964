import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { not } from 'logical-not';

import { Lang } from '../enums/lang';
import { StorageService } from '../services/storage.service';

class FormatterCache {
    private readonly options: Record<DateFormat, Intl.DateTimeFormatOptions> = {
        full: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        },
        date: {},
        time: {},
        timeFull: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        },
    };
    private readonly cache: Partial<
        Record<Lang, Partial<Record<DateFormat, Intl.DateTimeFormat>>>
    > = {};

    get(locale: Lang, format: DateFormat): Intl.DateTimeFormat {
        if (not(locale in this.cache)) this.cache[locale] = {};
        if (not(this.cache[locale]![format]))
            this.cache[locale]![format] = new Intl.DateTimeFormat(
                locale,
                this.options[format],
            );

        return this.cache[locale]![format]!;
    }
}

const formatters = new FormatterCache();

export type DateFormat = 'full' | 'date' | 'time' | 'timeFull';

@Pipe({ name: 'date', pure: false })
export class DatePipe implements PipeTransform {
    constructor(private storageService: StorageService) {}

    transform(
        date: Date | string | null | undefined,
        format: DateFormat = 'full',
    ): string {
        if (not(date)) return '';

        const locale = this.storageService.lang.value;

        return formatters.get(locale, format).format(new Date(date));
    }
}

@NgModule({
    exports: [DatePipe],
    declarations: [DatePipe],
})
export class DatePipeModule {}
