import { Pipe, PipeTransform } from '@angular/core';
import { ColumnType } from 'plmt-core-library';

@Pipe({
    name: 'columnTypeToIconName',
})
export class ColumnTypeToIconNamePipe implements PipeTransform {
    transform(type: ColumnType): string {
        switch (type) {
            case ColumnType.String:
                return 'type';
            case ColumnType.Number:
                return 'hash';
            case ColumnType.Boolean:
                return 'toggles';
            case ColumnType.Date:
                return 'calendar-week';
            // case ColumnType.Array:
            //     return 'stack';
            // case ColumnType.Object:
            //     return 'braces';
            default:
                return '';
        }
    }
}
