<label>{{ label }}</label>

<button
    #dropdownTarget
    type="button"
    class="select {{ size }}"
    [ngClass]="{
        active: isOpenDropdown,
        filled: !!value,
        disabled
    }"
>
    <span [class.placeholder]="!isExistValue(value)">
        {{
            isExistValue(value)
                ? selectedLabel
                : placeholder ?? ('_$.select.placeholder' | translate)
        }}
    </span>

    <i #dropdownShevron></i>
</button>

<core-dropdown
    #dropdown
    [button]="dropdownTarget"
    [shevron]="dropdownShevron"
    [shouldSyncWidth]="shouldSyncWidth"
    [visibleOverlay]="visibleOverlay"
    (show)="updateSelectedOptionIndex()"
    (hide)="hide()"
    (key)="handleKey($event)"
>
    <core-input
        *ngIf="searchProvider"
        autofocus
        clearable
        size="small"
        icon="search"
        (changeValue)="onSearch($event)"
    ></core-input>

    <div class="dropdown-wrap">
        <div class="dropdown">
            <div
                *ngFor="let option of currentLevelOptions; let i = index"
                class="option"
                [ngClass]="{
                    padding: option.prefixIcon || option.customIcon,
                    checked: isSelected(option),
                    border: option.border,
                    disabled: option.disabled,
                    selected: i === selectedOptionIndex
                }"
                (click)="!option.disabled && onSelectItem(option, i)"
            >
                <p>
                    <i *ngIf="option.prefixIcon">{{ option.prefixIcon }}</i>

                    <sl-icon
                        *ngIf="option.customIcon"
                        library="platform"
                        [name]="option.customIcon"
                    ></sl-icon>

                    <span>
                        <span tooltipify>{{ option.label }}</span>
                        <span *ngIf="option.hintText" class="hint-text">
                            {{ option.hintText }}
                        </span>
                    </span>
                </p>

                <i *ngIf="isMenu(option)">chevron_right</i>
            </div>

            <core-lazy-loading
                *ngIf="searchProvider || optionsProvider"
                [class.hidden]="currentLevelOptions.length === optionsTotal"
                [load]="more"
            >
            </core-lazy-loading>
        </div>
    </div>
</core-dropdown>
