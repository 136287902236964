<sl-icon *ngIf="!direction" slot="suffix" name="filter"></sl-icon>

<sl-icon
    *ngIf="direction === sortDirection.Asc"
    slot="suffix"
    class="sort-icon-asc"
    [name]="type === 'string' ? 'sort-alpha-up-alt' : 'sort-up'"
></sl-icon>

<sl-icon
    *ngIf="direction === sortDirection.Desc"
    slot="suffix"
    class="sort-icon-desc"
    [name]="type === 'string' ? 'sort-alpha-down-alt' : 'sort-down'"
></sl-icon>
