import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { not } from 'logical-not';
import { ToParent } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { tap } from 'rxjs';

import { isNullOrUndefined, valueToNumber } from 'plmt-core-library';
import {
    MaxDateLocalValue,
    MaxDateValue,
} from '../../../constants/max-date-value';
import { FilterTemplateType } from '../../../enums/filter-template-type';
import { FilterTemplateUIType } from '../../../enums/filter-ui-type';
import { DateFormat } from '../../../enums/global-filter';
import { FilterDateOffset } from '../../../interfaces/filter-template';
import { GetLabel, GetValue } from '../../../interfaces/items-operations';
import {
    FormControlAdapter,
    FormControlName,
    FormControlService,
} from '../../../services/form-control.service';
import { valueOf } from '../../../tools/value-of';
import { checkIfHasDefaultValue } from '../../filter-value/null.helper';
import { setDateFormat } from '../tools/filter-date-offset';

enum UIType {
    Text,
    Number,
    NumberRange,
    Date,
    DateRange,
    DateTime,
    DateTimeRange,
    Select,
    SelectMulti,
}

type TemplateUIType = FilterTemplateUIType | FilterTemplateType;

@Component({
    selector: 'plmt-filter-value-default',
    templateUrl: './filter-value-default.component.html',
    styleUrls: ['./filter-value-default.component.less'],
    providers: [FormControlService, ToParent],
    host: {
        '(mouseup)': '$event.stopPropagation()',
    },
})
export class FilterValueDefaultComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    name!: FormControlName;

    @Input()
    hasValueName!: FormControlName;

    @Input()
    defaultValues: any[] = [];

    @Input()
    validationError = false;

    @Input()
    set uiType(value: TemplateUIType) {
        this.type = this.getUIType(value);
        this.dateFormat = setDateFormat(value);
    }

    type = UIType.Text;
    dateFormat = DateFormat.Date;
    value: any;

    getLabelFn: GetLabel<any> = (item) => item;
    getValueFn: GetValue<any, any> = (item) => item;

    readonly UIType = UIType;
    readonly maxDateValue = MaxDateValue;
    readonly maxDateLocalValue = MaxDateLocalValue;

    private valueStream = new EventEmitter<any>();

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit(): void {
        this.formControlService.getControl(this.name, (control) =>
            this.formControlService.provide(control, () => {
                const setValue: FormControlAdapter['setValue'] = (value) => {
                    this.value = value;
                };

                return {
                    createValueStream: () =>
                        this.valueStream.pipe(
                            tap(setValue),
                            tap(this.setHasDefaultValue.bind(this)),
                        ),
                    setValue,
                };
            }),
        );

        this.formControlService.getControl(this.hasValueName, (control) =>
            this.subscriptions.push(
                this.valueStream.subscribe((value) =>
                    control.patchValue(checkIfHasDefaultValue(value)),
                ),
            ),
        );
    }

    toArray(value: any): any[] {
        if (not(value)) return [];

        return Array.isArray(value) ? value : value.split(', ');
    }

    emitChange(event: Event): void {
        const value = Array.isArray(event) ? event : valueOf(event);

        if (this.type === UIType.Number) {
            this.updateValue(valueToNumber(value));
        } else {
            this.updateValue(value);
        }
    }

    emitChangeDate(event: FilterDateOffset | null | string): void {
        this.updateValue(event);
    }

    emitChangeRange(event: Event, i: number): void {
        const value = valueOf(event) || null;

        if (this.type === UIType.NumberRange) {
            this.updateRangeValue(valueToNumber(value), i);
        } else {
            this.updateRangeValue(value, i);
        }
    }

    emitChangeDateRange(
        event: FilterDateOffset | null | string,
        i: number,
    ): void {
        const item = event || null;

        this.updateRangeValue(item, i);
    }

    private getUIType(type: TemplateUIType): UIType {
        switch (type) {
            case FilterTemplateUIType.Number:
                return UIType.Number;
            case FilterTemplateUIType.NumberRange:
                return UIType.NumberRange;
            case FilterTemplateUIType.DateCalendar:
            case FilterTemplateUIType.DateSearch:
                return UIType.Date;
            case FilterTemplateUIType.DateTimeCalendar:
            case FilterTemplateUIType.DateTimeSearch:
                return UIType.DateTime;
            case FilterTemplateUIType.DatePeriod:
            case FilterTemplateUIType.DateSearchPeriod:
                return UIType.DateRange;
            case FilterTemplateUIType.DateTimePeriod:
            case FilterTemplateUIType.DateTimeSearchPeriod:
                return UIType.DateTimeRange;
            case FilterTemplateType.Select:
                return UIType.Select;
            case FilterTemplateType.MultiSelect:
                return UIType.SelectMulti;
            default:
                return UIType.Text;
        }
    }

    private setHasDefaultValue(value: any) {
        if (!this.hasValueName) return;

        this.formControlService.getControl(this.hasValueName, (control) => {
            const existValue = checkIfHasDefaultValue(value);

            control.setValue(existValue);
        });
    }

    private updateValue(value: any): void {
        this.valueStream.emit(value);
    }

    private updateRangeValue(item: any, i: number): void {
        const value = Array.isArray(this.value) ? this.value.slice(0, 2) : [];

        value[0] = i === 0 ? item : (value[0] ?? null);
        value[1] = i === 1 ? item : (value[1] ?? null);

        if (value.every(isNullOrUndefined)) {
            this.valueStream.emit(null);
        } else {
            this.valueStream.emit(value);
        }
    }
}
