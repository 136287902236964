import { Component, ContentChild, Input, ViewChild } from '@angular/core';
import { SlDropdown } from '@shoelace-style/shoelace';
import { UpdateImmediately, WatchChanges } from 'ng-onpush';
import { SortDirection } from '../../../enums/sort';
import { PlmtDropdownComponent } from '../../../plmt-ui-components';
import { getHost } from '../../../tools/get-host';
import {
    ColumnEntityType,
    TableSortComponent,
    TableSortRoutingComponent,
} from '../table.module';

@Component({
    selector: 'core-thead-actions',
    templateUrl: './core-thead-actions.component.html',
    styleUrls: ['./core-thead-actions.component.less'],
})
export class CoreTheadActionsComponent {
    @Input()
    placement: SlDropdown['placement'] = 'bottom-end';

    @Input()
    by = '';

    @Input()
    type: ColumnEntityType = 'string';

    @UpdateImmediately()
    @ViewChild(TableSortRoutingComponent)
    readonly sortRoutingComponent!: TableSortRoutingComponent;

    @ContentChild(TableSortComponent)
    readonly sortComponent: TableSortComponent | null = null;

    @ViewChild('dropdown', { static: true })
    readonly dropdown!: PlmtDropdownComponent;

    readonly host = getHost();

    get direction(): SortDirection | undefined | null {
        return (this.sortRoutingComponent || this.sortComponent)?.direction;
    }
}
