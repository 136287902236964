import { SlTooltip } from '@shoelace-style/shoelace';
import { isNull } from 'lodash';
import { not } from 'logical-not';
import { Observable, finalize, first, fromEvent, switchMap, tap } from 'rxjs';

export const scope = {} as {
    tooltip: SlTooltip;
    positioner: HTMLElement;
};

let isShowTooltip = true;

export function tooltipify(
    interactor: HTMLElement,
    textProvider: HTMLElement,
    tester: () => boolean,
    delay: number | null = null,
): Observable<unknown> {
    return fromEvent(interactor, 'mouseenter').pipe(
        tap(() => {
            if (not(tester())) return;

            const { tooltip, positioner } = scope;
            const { left, top, height } = interactor.getBoundingClientRect();

            positioner.style.left = `${left}px`;
            positioner.style.top = `${top}px`;
            positioner.style.height = `${height}px`;

            tooltip.content = textProvider.textContent!;

            isShowTooltip = true;
            if (isNull(delay)) {
                tooltip.show();
            } else {
                showTooltip(tooltip, delay);
            }
        }),
        switchMap(() =>
            fromEvent(interactor, 'mouseleave').pipe(
                first(),
                finalize(() => {
                    isShowTooltip = false;
                    scope.tooltip.hide();
                }),
            ),
        ),
    );
}

function showTooltip(tooltip: SlTooltip, delay: number): void {
    setTimeout(() => {
        if (isShowTooltip) tooltip.show();
    }, delay);
}
