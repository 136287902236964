import {
    AfterViewInit,
    ContentChild,
    Directive,
    HostListener,
} from '@angular/core';
import { getHost } from 'src/shared/tools/get-host';
import { CoreTheadActionsComponent } from '../core-thead-actions/core-thead-actions.component';

@Directive({
    selector: '[center]',
})
export class CenterDirective implements AfterViewInit {
    readonly host = getHost();

    @ContentChild(CoreTheadActionsComponent)
    actionsComponent!: CoreTheadActionsComponent;

    @HostListener('mouseover')
    onMouseOver(): void {
        this.host.style.justifyContent = 'space-between';
    }

    @HostListener('mouseout')
    onMouseOut(): void {
        if (!this.actionsComponent) return;

        const {
            sortComponent,
            sortRoutingComponent,
            dropdown: { isOpen },
        } = this.actionsComponent;

        const { direction } = sortComponent || sortRoutingComponent;
        const condition = direction || isOpen;

        if (condition) this.host.style.gap = '8px';
        else this.host.style.justifyContent = 'center';

        this.host.style.transition = 'gap 250ms';
    }

    ngAfterViewInit(): void {
        this.host.style.display = 'flex';

        this.onMouseOut();
    }
}
