import { InjectionToken } from '@angular/core';
import { IconInput } from 'src/shared/interfaces/sl-icon.type';

export const ICON_TOKEN = new InjectionToken<IconTokenType<any>>('ICON_TOKEN');

export type IconTokenType<T> = (row: T) => {
    icon: IconInput;
    tooltip?: string;
    isTranslate?: boolean;
};
