import * as i0 from '@angular/core';
import { Injectable, Optional } from '@angular/core';
import { not } from 'logical-not';
import { fromEvent, filter, tap, map } from 'rxjs';
const event = `-${Math.random().toString(16).slice(2)}-${Date.now()}`;
class Message {}
class ToParent {
  constructor(hostRef) {
    this.hostRef = hostRef;
    if (not(hostRef)) throwInjectError(ToParent);
  }
  send(message, data) {
    const transfer = {
      message,
      data
    };
    const connectionEvent = new CustomEvent(event, {
      bubbles: true,
      detail: transfer
    });
    this.hostRef.nativeElement.dispatchEvent(connectionEvent);
  }
}
ToParent.ɵfac = function ToParent_Factory(t) {
  return new (t || ToParent)(i0.ɵɵinject(i0.ElementRef, 8));
};
ToParent.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ToParent,
  factory: ToParent.ɵfac,
  providedIn: "root"
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToParent, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
class FromChild {
  constructor(hostRef) {
    this.hostRef = hostRef;
    if (not(hostRef)) throwInjectError(FromChild);
  }
  listen(message) {
    return listen(this.hostRef.nativeElement, message);
  }
  pipe(message) {
    return listen(this.hostRef.nativeElement, message, true);
  }
}
FromChild.ɵfac = function FromChild_Factory(t) {
  return new (t || FromChild)(i0.ɵɵinject(i0.ElementRef, 8));
};
FromChild.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FromChild,
  factory: FromChild.ɵfac,
  providedIn: "root"
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FromChild, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
function listen(host, message, pipe = false) {
  return fromEvent(host, event).pipe(filter(({
    detail
  }) => {
    return detail.message === message;
  }), tap(event => {
    if (not(pipe)) event.stopPropagation();
  }), map(({
    detail: {
      data
    }
  }) => {
    return data;
  }));
}
function throwInjectError({
  name
}) {
  const message = `
Inject error: class ${name} must be provide in component:
  @Component({
      ...
      providers: [${name}]
  })
    `.trim();
  throw new Error(message);
}

/**
 * Generated bundle index. Do not edit.
 */

export { FromChild, Message, ToParent };
