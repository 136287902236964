<div *ngIf="breadcrumbs" class="center">
    <plmt-breadcrumbs [breadcrumbs]="breadcrumbs"></plmt-breadcrumbs>
</div>
<div class="center">
    <div class="content center gap-8 content-opacity">
        <h1>
            {{ title }}
        </h1>

        <ng-container
            *ngTemplateOutlet="
                checkedChoice.length ? counterChoice : counterDefault
            "
        ></ng-container>

        <plmt-badge
            *ngIf="globalTheme"
            pill
            light
            variant="primary"
            class="theme"
        >
            {{ currentTheme ? currentTheme.name : ('systemTheme' | translate) }}
            <sl-icon
                *ngIf="currentTheme"
                name="x"
                (click)="resetTheme.emit()"
            ></sl-icon>
        </plmt-badge>

        <div *ngIf="extra" class="extra center">
            <ng-container *ngTemplateOutlet="extra"></ng-container>
        </div>
    </div>

    <div class="controls center content-opacity">
        <ng-content></ng-content>
    </div>
    <div class="nav-user-container">
        <plmt-notification></plmt-notification>
        <plmt-profile></plmt-profile>
    </div>
</div>

<ng-template #counterDefault>
    <plmt-badge *ngIf="counter" pill light variant="primary">
        {{ counter }}
    </plmt-badge>
</ng-template>

<ng-template #counterChoice>
    <plmt-badge pill light variant="primary">
        {{ ('choiced.rows' | translate) + ' -' }} {{ checkedChoice.length }}
        <sl-icon name="x" (click)="reset.emit()"></sl-icon>
    </plmt-badge>
</ng-template>
