import { Injectable } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';

import { Page } from '../interfaces/page';
@Injectable({ providedIn: 'root' })
export class PageDataService {
    readonly page = new ReplaySubject<Page>(1);

    hasPermission = this.page.pipe(
        map(({ user, rights }) => user?.super_user || !!rights),
    );
}
