import { Component, HostBinding, Input } from '@angular/core';
import { SortDirection } from '../../../../enums/sort';
import { ColumnEntityType } from '../../table.module';

@Component({
    selector: 'core-thead-trigger',
    templateUrl: './core-thead-trigger.component.html',
    styleUrls: ['./core-thead-trigger.component.less'],
})
export class CoreTheadTriggerComponent {
    @HostBinding('attr.active')
    @Input()
    direction?: SortDirection | null = null;

    @Input()
    type: ColumnEntityType = 'string';

    @HostBinding('attr.slot')
    private readonly slot = 'trigger';

    readonly sortDirection = SortDirection;
}
