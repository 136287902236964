import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { tap } from 'rxjs';

import { ComponentSize } from '../../../interfaces/core-library-components';
import { DataProvider, DataSearchProvider } from '../../../interfaces/rest-api';
import {
    ControlComponent,
    ControlComponentRef,
} from '../../../modules/form/control-component';
import { Debounce } from '../../../tools/debounce';
import { isExistValue } from '../../../tools/exist-value';
import { getHost } from '../../../tools/get-host';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { toSelectItems } from '../tools/select-operator';
import { isEqual } from 'lodash';

type SelectOptionVariant<T> = T & {
    label: string;
    prefixIcon?: string;
    customIcon?: string;
    hintText?: string;
    disabled?: boolean;
    border?: boolean;
};

export type SelectOptionValue<T> = SelectOptionVariant<{ value: T }>;
export type SelectOptionMenu<T> = SelectOptionVariant<{
    options: SelectOption<T>[];
}>;

export type SelectOption<T = any> = SelectOptionValue<T> | SelectOptionMenu<T>;

export type SelectOptionProvider<T = any> = DataProvider<SelectOption<T>>;
export type SelectOptionSearchProvider<T = any> = DataSearchProvider<
    SelectOption<T>
>;

export type SelectMapperType = <Value, T>(item: T) => SelectOption<Value>;

const valueKey: keyof SelectOptionValue<any> = 'value';
const menuKey: keyof SelectOptionMenu<any> = 'options';

@Component({
    selector: 'core-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.less'],
    providers: [
        {
            provide: ControlComponentRef,
            useExisting: forwardRef(() => SelectComponent),
        },
    ],
})
export class SelectComponent
    extends SubscribableComponent
    implements OnChanges, ControlComponent
{
    static isValueOption = isValueOption;
    static isMenu = isMenu;

    @Input()
    label = '';

    @Input()
    placeholder?: string;

    @Input()
    size: ComponentSize = 'medium';

    @Input()
    disabled: boolean = false;

    @Input()
    set selected(selected: SelectOptionValue<any> | undefined) {
        if (selected) {
            this.value = selected.value;
            this.selectedLabel = selected.label;
        } else {
            this.value = undefined;
            this.selectedLabel = '';
        }
    }

    @Input()
    options: SelectOption[] = [];

    @Input()
    optionsProvider?: SelectOptionProvider;

    @Input()
    searchProvider?: SelectOptionSearchProvider;

    @Input()
    mapper?: SelectMapperType;

    @Input()
    shouldSyncWidth: boolean = true;

    @Input()
    visibleOverlay: boolean = true;

    @Output()
    changeValue = new EventEmitter<any>();

    @ViewChild('dropdown', { static: true })
    dropdown!: DropdownComponent;

    readonly host = getHost();
    readonly isMenu = isMenu;
    readonly isExistValue = isExistValue;

    readonly more = () => {
        const { optionsProvider, searchProvider, mapper } = this;

        const offset = this.options.length;

        let provider = searchProvider
            ? searchProvider(this.search, offset)
            : optionsProvider!(offset);

        if (mapper) provider = toSelectItems(mapper)(provider);

        return provider.pipe(
            tap(({ rows, total }) => {
                this.options.push(...rows);
                this.currentLevelOptions = this.options;
                this.optionsTotal = total;
            }),
        );
    };

    @WatchChanges()
    currentLevelOptions: SelectOption[] = [];

    levels: number[] = [];

    @WatchChanges()
    selectedLabel = '';

    @WatchChanges()
    selectedOptionIndex = 0;

    @WatchChanges()
    value: any;

    @WatchChanges()
    optionsTotal = -1;

    private search = '';

    isOpenDropdown: boolean = false;

    get focusedElement(): Element | null {
        return document.activeElement;
    }

    get keyboardFocusCondition(): boolean {
        return this.focusedElement instanceof HTMLInputElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('options' in changes) this.currentLevelOptions = this.options;
    }

    hide(): void {
        this.isOpenDropdown = false
    }

    onSelectItem(option: SelectOption, i: number): void {
        if (isValueOption(option)) {
            this.value = option.value;
            this.selectedLabel = option.label;

            this.changeValue.emit(this.value);

            this.dropdown.hide.emit();
        } else {
            this.levels.push(i);

            this.selectedOptionIndex = 0;
            this.currentLevelOptions = option.options;
        }
    }

    isSelected(option: SelectOption): boolean {
        if (isValueOption(option)) {
            const isSuccess = isEqual(option.value, this.value);

            if (isSuccess && !this.selectedLabel) {
                this.selectedLabel = option.label;
            }

            return isSuccess;
        }

        return false;
    }

    goBackLevel(): void {
        this.levels.pop();

        let current = this.options as SelectOptionMenu<any>[];

        for (let i of this.levels) {
            current = current[i].options as SelectOptionMenu<any>[];
        }

        this.currentLevelOptions = current;
    }

    updateSelectedOptionIndex(): void {
        this.isOpenDropdown = true;
        this.levels = [];
        this.currentLevelOptions = this.options;
        this.selectedOptionIndex = 0;

        if (isExistValue(this.value)) {
            const setLevels = (current: SelectOption[]) => {
                for (let [index, option] of current.entries()) {
                    if (isValueOption(option) && option.value === this.value) {
                        this.selectedOptionIndex = index;
                        this.currentLevelOptions = current;

                        break;
                    }

                    if (isMenu(option)) {
                        this.levels.push(index);

                        setLevels(option.options);
                    }
                }
            };

            setLevels(this.options);
        }
    }

    blurInput(): void {
        if (this.searchProvider && this.keyboardFocusCondition) {
            (this.focusedElement! as HTMLInputElement).blur();
        }
    }

    handleKey(key: string): void {
        switch (key) {
            case 'Enter':
                if (!this.keyboardFocusCondition) this.onKeyEnter();
                break;
            case 'ArrowUp':
                this.blurInput();
                this.onKeyArrowUp();
                break;
            case 'ArrowDown':
                this.blurInput();
                this.onKeyArrowDown();
                break;
            case 'ArrowLeft':
                if (!this.keyboardFocusCondition) this.onKeyArrowLeft();
                break;
            case 'ArrowRight':
                if (!this.keyboardFocusCondition) this.onKeyArrowRight();
                break;
        }
    }

    @Debounce(300)
    onSearch(search: string): void {
        this.search = search;

        this.resetOptions();
    }

    private resetOptions(): void {
        this.options = this.currentLevelOptions = [];
        this.optionsTotal = -1;
    }

    private onKeyEnter(): void {
        this.onSelectItem(
            this.currentLevelOptions[this.selectedOptionIndex],
            this.selectedOptionIndex,
        );
    }

    private onKeyArrowUp(): void {
        this.selectedOptionIndex -= 1;

        if (this.selectedOptionIndex < 0)
            this.selectedOptionIndex = this.currentLevelOptions.length - 1;
    }

    private onKeyArrowDown(): void {
        this.selectedOptionIndex += 1;

        if (this.selectedOptionIndex === this.currentLevelOptions.length)
            this.selectedOptionIndex = 0;
    }

    private onKeyArrowLeft(): void {
        if (this.levels.length) {
            this.selectedOptionIndex = this.levels[this.levels.length - 1];

            this.goBackLevel();
        }
    }

    private onKeyArrowRight(): void {
        const option = this.currentLevelOptions[this.selectedOptionIndex];

        if (isMenu(option)) {
            this.levels.push(this.selectedOptionIndex);

            this.selectedOptionIndex = 0;
            this.currentLevelOptions = option.options;
        }
    }
}

function isValueOption<T>(
    source: SelectOption<T>,
): source is SelectOptionValue<T> {
    return valueKey in source;
}

function isMenu<T>(source: SelectOption<T>): source is SelectOptionMenu<T> {
    return menuKey in source;
}
