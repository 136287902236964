import { Directive, Input, OnInit } from '@angular/core';
import { SlMenuItem, SlOption, SlRadio } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';

import { Debounce } from '../../tools/debounce';
import { getHost } from '../../tools/get-host';
import { tooltipify } from './tooltipify.internal';

@Directive({ selector: '[tooltipify]' })
export class TooltipifyDirective
    extends SubscribableDirective
    implements OnInit
{
    @Input()
    tooltipify: '' = '';

    @Input()
    delay: number | null = null;

    private readonly host = getHost();

    ngOnInit(): void {
        const { host } = this;

        if (host instanceof SlMenuItem) {
            this.initSlMenuItem(host);

            return;
        }

        if (host instanceof SlRadio) {
            this.initSlRadio(host);

            return;
        }

        if (host instanceof SlOption) {
            this.initSlOption(host);

            return;
        }

        this.initDefault(host);
    }

    private initDefault(host: HTMLElement): void {
        this.subscribe(host, host);
    }

    @Debounce()
    private initSlMenuItem(menuItem: SlMenuItem): void {
        const label = menuItem.shadowRoot?.querySelector(
            '.menu-item__label',
        ) as HTMLElement;

        if (!label) return;

        this.subscribe(label, menuItem);
    }

    @Debounce()
    private initSlRadio(radio: SlRadio): void {
        const label = radio.shadowRoot!.querySelector(
            '.radio__label',
        ) as HTMLElement;

        this.subscribe(label, radio);
    }

    @Debounce()
    private initSlOption(option: SlOption): void {
        const label = option.shadowRoot!.querySelector(
            '.option__label',
        ) as HTMLElement;

        this.subscribe(label, option);
    }

    private subscribe(
        interactor: HTMLElement,
        textProvider: HTMLElement,
    ): void {
        this.subscriptions.push(
            tooltipify(
                interactor,
                textProvider,
                () => interactor.scrollWidth > interactor.clientWidth,
                this.delay,
            ).subscribe(),
        );
    }
}
