import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { SlDropdown } from '@shoelace-style/shoelace';
import { getHost, PlmtDropdownComponent, TestId } from 'plmt-core-library';

export type ActionDropdownComponentType =
    | 'delete'
    | 'update'
    | 'copy'
    | 'apply'
    | 'export'
    | 'edit';

interface Action {
    type: ActionDropdownComponentType;
    hasAccess: boolean;
    template?: TemplateRef<any>;
}

@Component({
    selector: 'core-action-dropdown[actions]',
    templateUrl: './action-dropdown.component.html',
    styleUrls: ['./action-dropdown.component.less'],
})
export class ActionDropdownComponent {
    @Input()
    actions!: Action[];

    @Input()
    placement: SlDropdown['placement'] = 'bottom-start';

    @Output()
    onCopy = new EventEmitter<void>();

    @Output()
    onApply = new EventEmitter<void>();

    @Output()
    onUpdate = new EventEmitter<void>();

    @Output()
    onDelete = new EventEmitter<void>();

    @Output()
    onExport = new EventEmitter<void>();

    @Output()
    onEdit = new EventEmitter<void>();

    @ViewChild('dropdown', { static: true })
    dropdown!: PlmtDropdownComponent;

    get hasLeastOneAccess(): boolean {
        return this.actions.some((item) => item.hasAccess);
    }

    // NOTE: отвечает за отображение actions в таблицах
    @HostBinding('attr.active')
    private get isActive(): '' | undefined {
        return this.dropdown?.isOpen ? '' : void 0;
    }

    @HostListener('click', ['$event'])
    stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    host = getHost();

    onChange(action: Action): void {
        if (!action.hasAccess) return;
        switch (action.type) {
            case 'apply':
                this.onApply.emit();
                break;
            case 'delete':
                this.onDelete.emit();
                break;
            case 'copy':
                this.onCopy.emit();
                break;
            case 'update':
                this.onUpdate.emit();
                break;
            case 'export':
                this.onExport.emit();
                break;
            case 'edit':
                this.onEdit.emit();
        }
    }

    getTestId(type: ActionDropdownComponentType): keyof typeof TestId {
        switch (type) {
            case 'apply':
                return 'Apply';
            case 'copy':
                return 'Copy';
            case 'update':
                return 'Update';
            case 'export':
                return 'Download';
            default:
                return 'Delete';
        }
    }
}
