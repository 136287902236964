import * as i0 from '@angular/core';
import { Component, Directive, Injectable } from '@angular/core';
class SubscribableComponent {
  constructor() {
    this.subscriptions = [];
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
SubscribableComponent.ɵfac = function SubscribableComponent_Factory(t) {
  return new (t || SubscribableComponent)();
};
SubscribableComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SubscribableComponent,
  selectors: [["ng-component"]],
  decls: 0,
  vars: 0,
  template: function SubscribableComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubscribableComponent, [{
    type: Component,
    args: [{
      template: ""
    }]
  }], null, null);
})();
class SubscribableDirective {
  constructor() {
    this.subscriptions = [];
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
SubscribableDirective.ɵfac = function SubscribableDirective_Factory(t) {
  return new (t || SubscribableDirective)();
};
SubscribableDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SubscribableDirective
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubscribableDirective, [{
    type: Directive
  }], null, null);
})();
class SubscribableService {
  constructor() {
    this.subscriptions = [];
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
SubscribableService.ɵfac = function SubscribableService_Factory(t) {
  return new (t || SubscribableService)();
};
SubscribableService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SubscribableService,
  factory: SubscribableService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubscribableService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SubscribableComponent, SubscribableDirective, SubscribableService };
