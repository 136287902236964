import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';

import { Debounce } from '../../tools/debounce';

@Component({
    selector: 'core-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements AfterViewInit {
    @Input()
    routing = true;

    @Input()
    total = 0;

    @Input()
    limit = 30;

    @Input()
    loading = false;

    @Input()
    stickyHeader = false;

    @Input()
    hasFooter = false;

    @Input()
    @HostBinding('class.overflow')
    overflow = false;

    @Input()
    set nested(_: '') {
        this.addNestedClass = true;
    }

    @Input()
    tableMaxHeight = 'none';

    @HostBinding('class.inner')
    private addNestedClass = false;

    constructor(private hostRef: ElementRef<HTMLElement>) {}

    @Debounce()
    ngAfterViewInit(): void {
        if (!this.stickyHeader) {
            this.stickyHeader = Boolean(
                this.hostRef.nativeElement.closest('sl-dialog'),
            );
        }
    }
}
