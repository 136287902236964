import { isArray, isNull, isUndefined } from 'lodash';
import { searchFilterMethodsMap } from '../constants/filter-methods-provider';
import { ColumnType } from '../enums/dataset';
import { FilterTemplateUIType } from '../enums/filter-ui-type';
import { GlobalFilter } from '../interfaces/global-filter.interface';
import { Dependence } from 'dashboard/src/app/services/project/project.service';

export function convertValueToProperType(
    globalFilter: GlobalFilter,
    dependence: Dependence,
): number | string | any[] {
    const searchMethods = searchFilterMethodsMap[FilterTemplateUIType.Search];
    const dependenceData = dependence.data;
    const { value, action } = globalFilter;

    if (
        typeof value !== 'string' ||
        !searchMethods!.includes(action) ||
        !dependenceData
    ) {
        return castToCorrectType(value, dependence.bindingColumnType);
    }

    if (
        !dependence.bindingColumnType ||
        dependence.bindingColumnType !== ColumnType.Number
    ) {
        return value;
    }

    const replaced = (value as string).replace(/,/g, '.');
    return isNaN(+replaced) ? value : +replaced;
}

function castToCorrectType(
    value: any,
    columnType: ColumnType | undefined,
): string | number | Array<any> {
    if (isUndefined(columnType)) return value;

    const checkType = (value: any) => {
        if (isNull(value)) return value;

        switch (columnType) {
            case ColumnType.Number:
                return parseFloat(value);
            default:
                return String(value);
        }
    };

    if (isArray(value)) {
        value = value.map((element) => checkType(element));
    } else {
        value = checkType(value);
    }

    return value;
}
