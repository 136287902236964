import {
    AfterContentInit,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    QueryList,
} from '@angular/core';
import { not } from 'logical-not';
import { debounceTime, first, Subject, Subscription } from 'rxjs';

import { ignorable, SetIgnoreFor } from '../../../tools/ignorable';
import { TableCheckboxComponent } from '../table-checkbox/table-checkbox.component';
import { TableCheckboxControllerComponent } from './table-checkbox-controller.component';

interface CheckboxSubscriptions {
    change: Subscription;
    destroy: Subscription;
}

enum IgnorableKey {
    ControllerChanges = 'controller',
    CheckboxsChanges = 'checkboxs',
}

@Directive({
    selector: 'core-table',
})
export class TableCheckboxControllerDirective implements AfterContentInit {
    @ContentChild(TableCheckboxControllerComponent, { static: true })
    controller!: TableCheckboxControllerComponent;

    @ContentChildren(TableCheckboxComponent, { descendants: true })
    checkboxs!: QueryList<TableCheckboxComponent>;

    private checkboxSubscriptionsMap = new Map<
        TableCheckboxComponent,
        CheckboxSubscriptions
    >();

    private deleted = new Subject<void>();
    private deletedSubscription!: Subscription;

    private get checkboxsIterator(): IterableIterator<TableCheckboxComponent> {
        return this.checkboxSubscriptionsMap.keys();
    }

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    ngAfterContentInit(): void {
        // cancel if core-table has no core-table-checkbox-controller
        if (not(this.controller)) return;

        this.deletedSubscription = this.deleted
            .pipe(debounceTime(0))
            .subscribe(() => this.emitChange());

        this.controller.ready.pipe(first()).subscribe(() => {
            const controllerSubscriptions = {
                change: this.controller.checkbox.change
                    .pipe(ignorable(this, IgnorableKey.ControllerChanges))
                    .subscribe(() => {
                        this.onControllerChange();
                    }),
                destroy: this.controller.checkbox.destroy.subscribe(() => {
                    this.disposeCheckboxSubscriptions(controllerSubscriptions);

                    checkboxChangesSubscription.unsubscribe();
                    this.deletedSubscription.unsubscribe();
                    resetCheckboxes.unsubscribe();
                }),
            };

            const checkboxChangesSubscription =
                this.checkboxs.changes.subscribe((checkboxs) =>
                    this.registerCheckboxs(checkboxs),
                );

            const resetCheckboxes = this.controller.reset.subscribe(() => {
                if (!this.controller) return;

                const { checkbox } = this.controller;

                checkbox.checked = checkbox.indeterminate = false;
                checkbox.change.emit();
            });

            this.registerCheckboxs(this.checkboxs);
        });
    }

    private registerCheckboxs(
        checkboxs: QueryList<TableCheckboxComponent>,
    ): void {
        checkboxs.forEach((checkbox) => {
            if (this.checkboxSubscriptionsMap.has(checkbox)) return;

            const ownComponent =
                checkbox.element.nativeElement.closest('core-table') ===
                this.elementRef.nativeElement;

            if (ownComponent) this.registerCheckbox(checkbox);
        });
    }

    private registerCheckbox(checkbox: TableCheckboxComponent): void {
        const item: CheckboxSubscriptions = {
            change: checkbox.change
                .pipe(ignorable(this, IgnorableKey.CheckboxsChanges))
                .subscribe(() => {
                    this.onCheckboxChange();
                }),
            destroy: checkbox.destroy.subscribe(() => {
                this.disposeCheckboxSubscriptions(item);

                this.checkboxSubscriptionsMap.delete(checkbox);
                this.deleted.next();

                this.checkAllCheckboxDeleted();
            }),
        };

        this.checkboxSubscriptionsMap.set(checkbox, item);
    }

    @SetIgnoreFor(IgnorableKey.ControllerChanges)
    private onCheckboxChange(): void {
        this.setControllerState();
        this.emitChange();
    }

    @SetIgnoreFor(IgnorableKey.CheckboxsChanges)
    private onControllerChange(): void {
        const { checked } = this.controller.checkbox;

        for (let checkbox of this.checkboxsIterator) {
            checkbox.checked = checked;
        }

        this.emitChange();
    }

    private emitChange(): void {
        const data: any[] = [];

        for (let checkbox of this.checkboxsIterator) {
            if (checkbox.checked) data.push(checkbox.value);
        }

        this.controller.change.emit(data);
    }

    @SetIgnoreFor(IgnorableKey.ControllerChanges)
    private checkAllCheckboxDeleted(): void {
        if (this.checkboxSubscriptionsMap.size === 0) {
            this.controller.checkbox.checked = false;
            this.controller.checkbox.indeterminate = false;
        } else {
            this.setControllerState();
        }
    }

    private disposeCheckboxSubscriptions(item: CheckboxSubscriptions): void {
        item.change.unsubscribe();
        item.destroy.unsubscribe();
    }

    private setControllerState(): void {
        let checked = true;
        let indeterminate = false;

        for (let checkbox of this.checkboxsIterator) {
            if (not(checkbox.checked)) {
                checked = false;

                break;
            } else {
                indeterminate = true;
            }
        }

        if (not(checked && indeterminate))
            for (let checkbox of this.checkboxsIterator) {
                if (checkbox.checked) {
                    indeterminate = true;

                    break;
                }
            }

        this.controller.checkbox.checked = checked;
        this.controller.checkbox.indeterminate = indeterminate;
    }
}
