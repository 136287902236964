import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TrackByIdModule } from 'ng-track-by';
import { ShoelaceModule } from 'shoelace-style-angular';

import { PlmtToggleItemComponent } from '../../../shared/plmt-ui-components/plmt-toggle/plmt-toggle-item/plmt-toggle-item.component';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { FormModule } from '../../modules/form/form.module';
import { TranslateModule } from '../../modules/translate/translate.module';
import {
    PlmtButtonComponent,
    PlmtDropdownModule,
    PlmtToggleComponent,
} from '../../plmt-ui-components';
import {
    ActionDropdownComponent,
    ActionDropdownModule,
} from '../action-dropdown/action-dropdown.module';
import { PaginationModule } from '../pagination/pagination.module';
import { SortIndicatorModule } from '../sort-indicator/sort-indicator.module';
import { CoreTheadActionsComponent } from './core-thead-actions/core-thead-actions.component';
import { CoreTheadTriggerComponent } from './core-thead-actions/core-thead-sort-icon/core-thead-trigger.component';
import { CalculateTableHeadWidthDirective } from './directives/calculate-table-head-width.directive';
import { CenterDirective } from './directives/center.directive';
import { TableTextOverflow } from './directives/table-text-overflow.directive';
import {
    MaxWidthDirective,
    MinWidthDirective,
    MinWidthThDirective,
    WidthDirective,
} from './directives/width.directive';
import { TableCheckboxControllerComponent } from './table-checkbox-controller/table-checkbox-controller.component';
import { TableCheckboxControllerDirective } from './table-checkbox-controller/table-checkbox-controller.directive';
import { TableCheckboxComponent } from './table-checkbox/table-checkbox.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { TableIconComponent } from './table-icon/table-icon.component';
import { TableSelfCheckboxFilterComponent } from './table-self-checkbox-filter/table-self-checkbox-filter.component';
import { TableSortRoutingComponent } from './table-sort-routing/table-sort-routing.component';
import { TableSortComponent } from './table-sort/table-sort.component';
import { TableSortDirective } from './table-sort/table-sort.directive';
import { TableComponent } from './table.component';
import { AutoCompleteOffDirectiveModule } from '../../directives/autocomplete-off.directive';

export * from './table-checkbox/table-checkbox.component';
export * from './table.component';

export * from './directives/table-text-overflow.directive';
export * from './directives/width.directive';
export * from './table-checkbox-controller/table-checkbox-controller.component';
export * from './table-checkbox-controller/table-checkbox-controller.directive';
export * from './table-checkbox/table-checkbox.component';
export * from './table-filter/table-filter.component';
export * from './table-filter/table-filter.interfaces';
export * from './table-icon/table-icon.component';
export * from './table-self-checkbox-filter/table-self-checkbox-filter.component';
export * from './table-sort-routing/table-sort-routing.component';
export * from './table-sort/table-sort.component';
export * from './table-sort/table-sort.directive';
export * from './table.component';

@NgModule({
    imports: [
        ActionDropdownModule,
        CommonModule,
        FormModule,
        PaginationModule,
        PlmtButtonComponent,
        PlmtToggleComponent,
        PlmtToggleItemComponent,
        PlmtDropdownModule,
        ShoelaceModule,
        SortIndicatorModule,
        TrackByIdModule,
        TableIconComponent,
        TranslateModule,
        TestIdDirectiveModule,
        AutoCompleteOffDirectiveModule,
    ],
    exports: [
        ActionDropdownComponent,
        CenterDirective,
        MaxWidthDirective,
        MinWidthDirective,
        MinWidthThDirective,
        CalculateTableHeadWidthDirective,
        TableComponent,
        TableCheckboxComponent,
        TableCheckboxControllerComponent,
        TableIconComponent,
        TableCheckboxControllerDirective,
        CoreTheadActionsComponent,
        CoreTheadTriggerComponent,
        TableFilterComponent,
        TableSortComponent,
        TableSortDirective,
        TableSelfCheckboxFilterComponent,
        TableTextOverflow,
        WidthDirective,
    ],
    declarations: [
        MaxWidthDirective,
        MinWidthDirective,
        MinWidthThDirective,
        CalculateTableHeadWidthDirective,
        CenterDirective,
        TableComponent,
        TableCheckboxComponent,
        TableCheckboxControllerComponent,
        TableCheckboxControllerDirective,
        TableFilterComponent,
        CoreTheadTriggerComponent,
        CoreTheadActionsComponent,
        TableSortComponent,
        TableSortRoutingComponent,
        TableSortDirective,
        TableSelfCheckboxFilterComponent,
        TableTextOverflow,
        WidthDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableModule {}
