import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { cloneDeep, isArray } from 'lodash';

import { DefaultValueAggregation } from '../enums/radio-min-max-state.enum';
import { FilterTemplateApiService } from '../api/filter-template-api.service';
import { AlertService } from '../components/alert/alert.service';
import { ColumnType } from '../enums/dataset';
import { FilterTemplateType } from '../enums/filter-template-type';
import {
    KeyView,
    AggFnValue,
    FilterTemplate,
} from '../interfaces/filter-template';
import { not } from 'logical-not';
import {
    NULL_VALUE,
    checkIfHasDefaultValue,
} from '../components/filter-value/null.helper';
import { FilterTemplateUIType } from '../enums/filter-ui-type';
import { FilterTemplateUI } from '../components/filter-template/interfaces/filter-template-ui.type';
import { hasAggFn } from '../components/filter-template/form/form-helper';
import { isNullOrUndefined } from '../helpers/is-null-or-undefined';

@Injectable()
export class FilterTemplateService {
    onChangeDefaultValue = new EventEmitter<any>();
    onLoadMinMaxDefaultValue = new EventEmitter<FilterTemplate>();

    constructor(
        private filterTemplateApiService: FilterTemplateApiService,
        private alertService: AlertService,
    ) {
        this.onLoadMinMaxDefaultValue.subscribe((filter) => {
            this.getDefaultValue(filter);
        });
    }

    resetDefaultValue(): void {
        this.emitDefaultValue(null);
    }

    joinDefaultValueIntoOneParam(defaultValue: any): any {
        return hasAggFn(defaultValue) ? { view: defaultValue } : defaultValue;
    }

    // проверяет всевозможные кейсы дефолтного значения
    hasDefaultValue(filter?: FilterTemplate): boolean {
        const valueHasView = Boolean(
            filter?.default_value?.hasOwnProperty('view'),
        );
        const valueHasAggFn = Boolean(hasAggFn(filter?.default_value?.view));
        const valueHasAnotherValue = checkIfHasDefaultValue(
            filter?.default_value,
        );

        switch (true) {
            case valueHasAggFn:
                return (
                    filter?.default_value?.value?.agg_fn !==
                    DefaultValueAggregation.Exact
                );
            case valueHasView:
                return checkIfHasDefaultValue(filter?.default_value.view);
            default:
                return valueHasAnotherValue;
        }
    }

    canShowMinMax(
        columnBaseType?: ColumnType,
        filterSubType?: FilterTemplateUIType,
        filterTemplateType?: FilterTemplateType,
    ): boolean {
        if (
            !columnBaseType ||
            !filterTemplateType ||
            filterSubType === FilterTemplateUIType.TreeView
        )
            return false;

        const isNumberOrDate =
            columnBaseType === ColumnType.Number ||
            columnBaseType === ColumnType.Date;
        const typeIsSelect = filterTemplateType == FilterTemplateType.Select;

        return isNumberOrDate && typeIsSelect;
    }

    mapFormDataToFilterParams(
        formValue: Partial<FilterTemplateUI>,
        selectedColumnType?: ColumnType,
    ): FilterTemplate {
        const data = this.getFormData(formValue);

        if (
            selectedColumnType === ColumnType.Number ||
            selectedColumnType === ColumnType.Date
        ) {
            data.default_value = checkIfHasDefaultValue(data.default_value)
                ? data.default_value
                : undefined;
        }

        return data;
    }

    private getFormData(formValue: Partial<FilterTemplateUI>): FilterTemplate {
        const data = cloneDeep(formValue);

        // фильтр может быть одним из четырех типов ниже

        const isCustom = Boolean(data.custom_select);

        const isDataset = Boolean(data.dataset_select);

        const isCommon = !isDataset && !isCustom;

        const isTree = data.sub_type === FilterTemplateUIType.TreeView;

        if (isCommon) {
            this.handleCommonFilter(data);
        }

        if (isCustom) {
            this.handleCustomFilter(data);
        }

        if (isDataset) {
            this.handleDatasetFilter(data);
        }

        if (isTree) {
            this.handleTreeFilter(data);
        }

        this.handleDefaultValue(data);

        delete data.filters;
        delete data.default_value_agg_fn;
        delete data.original_default_value;
        delete data.dataset_id;
        delete data.custom_select?.key_column_type;
        delete data.custom_select?.suggest_column_type;

        return data as FilterTemplate;
    }

    private handleCommonFilter(formValue: Partial<FilterTemplateUI>): void {
        if (isNullOrUndefined(formValue.default_value))
            delete formValue.default_value;
    }

    private handleCustomFilter(formValue: Partial<FilterTemplateUI>): void {
        const { key_column_type, suggest_column_type, values } =
            formValue.custom_select!;

        const newValues =
            values?.map((item) => ({
                label: this.castCustomValue(item.label, suggest_column_type!),
                key: item.key
                    ? this.castCustomValue(item.key, key_column_type!)
                    : undefined,
            })) || null;

        formValue.custom_select!.values = newValues;
    }

    private handleDatasetFilter(formValue: Partial<FilterTemplateUI>): void {
        formValue.dataset_select!.filters = formValue.filters || [];
        formValue.dataset_select!.dataset_id = formValue.dataset_id!;
    }

    private handleTreeFilter(formValue: Partial<FilterTemplateUI>): void {
        formValue.tree_select!.filters = formValue.filters || [];

        formValue.tree_select!.dataset_id = formValue.dataset_id!;

        formValue.tree_select?.columns.forEach((column) => {
            if (!column.sort?.order_by) {
                column.sort = undefined;
            }
        });
    }

    private handleDefaultValue(formValue: Partial<FilterTemplateUI>): void {
        if (formValue.default_value_agg_fn !== DefaultValueAggregation.Exact) {
            formValue.default_value = {
                view: {
                    agg_fn: formValue.default_value_agg_fn,
                } as AggFnValue,
            } as KeyView;
            formValue.has_default_value = true;
        }

        if (formValue.default_value === NULL_VALUE) {
            formValue.default_value = null;
            formValue.has_default_value = true;
        }
    }

    private emitDefaultValue(defaultValue: any): void {
        this.onChangeDefaultValue.emit(defaultValue);
    }

    private getDefaultValue(filterTemplate: FilterTemplate | undefined): void {
        if (!filterTemplate) return;

        this.filterTemplateApiService
            .getFilterDefaultValue(filterTemplate)
            .subscribe({
                next: (filterValue) => {
                    this.onChangeDefaultValue.emit(filterValue);
                },
                error: (response: HttpErrorResponse) => {
                    this.alertService.show.emit({
                        responseError: response,
                        key: '_$$.filterTemplate.getFilterError',
                    });
                },
            });
    }

    private castCustomValue(
        val: number | string,
        type: ColumnType,
    ): number | string {
        return type === ColumnType.String ? val.toString() : +val;
    }

    // на случай, если нужно будет добавить поддержку мультиселекта
    private handleDefaultFilterValue(value: any, filterType: string): any {
        if (
            filterType === FilterTemplateType.MultiSelect.toString() &&
            !isArray(value)
        ) {
            value = value === null ? null : [value];
        }

        return value;
    }
}
