<core-dialog
    [label]="'_$$.filters.label.dialog.header' | translate"
    [preventClose]="PreventClose.OnlyAnimate"
    (hide)="onHide()"
    [(open)]="isOpen"
>
    <core-form [data]="form" (submit)="submit()">
        <div class="content">
            <div class="overlay" [class.overlay-flex]="form.controls.length">
                <div class="overlay-content">
                    <div [class.wrapper]="!form.controls.length">
                        <div
                            *ngIf="!form.controls.length; else filterRows"
                            class="start-state"
                        >
                            <span>
                                {{
                                    '_$$.filters.label.createEntity' | translate
                                }}
                            </span>
                            <ng-container
                                *ngTemplateOutlet="createConditions"
                            ></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <core-button
                variant="outline"
                [disabled]="!form.controls.length"
                (buttonClick)="clearModal.open()"
            >
                {{ '_$$.filters.button.clear' | translate }}
            </core-button>

            <div class="modal-actions">
                <core-button
                    variant="outline"
                    test-id="Cancel"
                    (buttonClick)="isOpen = false"
                >
                    {{ '$.cancel' | translate }}
                </core-button>

                <core-button submit variant="primary" test-id="Save">
                    {{ '$.save' | translate }}!
                </core-button>
            </div>
        </div>
    </core-form>
</core-dialog>

<ng-template #createConditions>
    <div class="create-conditions" [class.reposition]="form.controls.length">
        <span>{{ '_$$.filters.label.create' | translate }}</span>

        <div class="create-buttons">
            <core-button variant="neutral" (buttonClick)="addRule()">
                {{ '_$$.filters.button.add.rule' | translate }}
            </core-button>
            <core-button variant="neutral" (buttonClick)="addGroup()">
                {{ '_$$.filters.button.add.group' | translate }}
            </core-button>
        </div>
    </div>
</ng-template>

<ng-template #filterRows>
    <core-filter-row
        cdkDropList
        id="main"
        [cdkDropListData]="form"
        [cdkDropListConnectedTo]="localFiltersService.dropTargetIds"
        [cdkDropListSortingDisabled]="true"
        [localForm]="form"
        (cdkDropListDropped)="localFiltersService.drop($event)"
    ></core-filter-row>

    <ng-container *ngTemplateOutlet="createConditions"></ng-container>
</ng-template>

<filter-clear-modal #clearModal (clear)="form.clear()"></filter-clear-modal>
