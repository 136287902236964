import * as i0 from '@angular/core';
import { Directive, EventEmitter, Input, Output, NgModule } from '@angular/core';
import { SubscribableDirective } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
class AutofocusDirective extends SubscribableDirective {
  constructor(hostRef) {
    super();
    this.hostRef = hostRef;
  }
  ngAfterViewInit() {
    const host = this.hostRef.nativeElement;
    setTimeout(() => host.focus());
  }
}
AutofocusDirective.ɵfac = function AutofocusDirective_Factory(t) {
  return new (t || AutofocusDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
AutofocusDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AutofocusDirective,
  selectors: [["sl-input", "autofocus", ""], ["sl-textarea", "autofocus", ""], [""]],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutofocusDirective, [{
    type: Directive,
    args: [{
      selector: `
        sl-input[autofocus],
        sl-textarea[autofocus],
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();
function observe(element, eventName) {
  return fromEvent(element, eventName).pipe(filter(event => event?.target === element));
}
class OpenDirective extends SubscribableDirective {
  constructor(hostRef) {
    super();
    this.hostRef = hostRef;
    this.open = false;
    this.openChange = new EventEmitter();
  }
  ngOnInit() {
    const host = this.hostRef.nativeElement;
    this.subscriptions = [observe(host, "sl-after-show").subscribe(() => this.changeOpen(true)), observe(host, "sl-after-hide").subscribe(() => this.changeOpen(false))];
  }
  ngOnChanges(changes) {
    if (changes.open) this.hostRef.nativeElement.open = changes.open.currentValue;
  }
  changeOpen(value) {
    this.open = value;
    this.openChange.emit(value);
  }
}
OpenDirective.ɵfac = function OpenDirective_Factory(t) {
  return new (t || OpenDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
OpenDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OpenDirective,
  selectors: [["sl-alert", "open", ""], ["sl-color-picker", "open", ""], ["sl-details", "open", ""], ["sl-dialog", "open", ""], ["sl-drawer", "open", ""], ["sl-dropdown", "open", ""], ["sl-tooltip", "open", ""], [""]],
  inputs: {
    open: "open"
  },
  outputs: {
    openChange: "openChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OpenDirective, [{
    type: Directive,
    args: [{
      selector: `
        sl-alert[open],
        sl-color-picker[open],
        sl-details[open],
        sl-dialog[open],
        sl-drawer[open],
        sl-dropdown[open],
        sl-tooltip[open],
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    open: [{
      type: Input
    }],
    openChange: [{
      type: Output
    }]
  });
})();
class OpenableDirective extends SubscribableDirective {
  constructor(hostRef) {
    super();
    this.hostRef = hostRef;
    this.show = new EventEmitter();
    this.hide = new EventEmitter();
    this.afterShow = new EventEmitter();
    this.afterHide = new EventEmitter();
  }
  ngOnInit() {
    const host = this.hostRef.nativeElement;
    this.subscriptions = [observe(host, "sl-show").subscribe(event => this.show.emit(event)), observe(host, "sl-hide").subscribe(event => this.hide.emit(event)), observe(host, "sl-after-show").subscribe(event => this.afterShow.emit(event)), observe(host, "sl-after-hide").subscribe(event => this.afterHide.emit(event))];
  }
}
OpenableDirective.ɵfac = function OpenableDirective_Factory(t) {
  return new (t || OpenableDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
OpenableDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OpenableDirective,
  selectors: [["sl-alert"], ["sl-details"], ["sl-dialog"], ["sl-drawer"], ["sl-dropdown"], ["sl-tooltip"], [""]],
  outputs: {
    show: "show",
    hide: "hide",
    afterShow: "afterShow",
    afterHide: "afterHide"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OpenableDirective, [{
    type: Directive,
    args: [{
      selector: `
        sl-alert,
        sl-details,
        sl-dialog,
        sl-drawer,
        sl-dropdown,
        sl-tooltip,
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    show: [{
      type: Output
    }],
    hide: [{
      type: Output
    }],
    afterShow: [{
      type: Output
    }],
    afterHide: [{
      type: Output
    }]
  });
})();
class OpenableExtendedDirective extends SubscribableDirective {
  constructor(hostRef) {
    super();
    this.hostRef = hostRef;
    this.initialFocus = new EventEmitter();
    this.requestClose = new EventEmitter();
  }
  ngOnInit() {
    const host = this.hostRef.nativeElement;
    this.subscriptions = [observe(host, "sl-initial-focus").subscribe(event => this.initialFocus.emit(event)), observe(host, "sl-request-close").subscribe(event => this.requestClose.emit(event))];
  }
}
OpenableExtendedDirective.ɵfac = function OpenableExtendedDirective_Factory(t) {
  return new (t || OpenableExtendedDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
OpenableExtendedDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OpenableExtendedDirective,
  selectors: [["sl-dialog"], ["sl-drawer"], [""]],
  outputs: {
    initialFocus: "initialFocus",
    requestClose: "requestClose"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OpenableExtendedDirective, [{
    type: Directive,
    args: [{
      selector: `
        sl-dialog,
        sl-drawer,
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    initialFocus: [{
      type: Output
    }],
    requestClose: [{
      type: Output
    }]
  });
})();
class ShoelaceModule {}
ShoelaceModule.ɵfac = function ShoelaceModule_Factory(t) {
  return new (t || ShoelaceModule)();
};
ShoelaceModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ShoelaceModule
});
ShoelaceModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShoelaceModule, [{
    type: NgModule,
    args: [{
      exports: [AutofocusDirective, OpenableDirective, OpenableExtendedDirective, OpenDirective],
      declarations: [AutofocusDirective, OpenableDirective, OpenableExtendedDirective, OpenDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AutofocusDirective, OpenDirective, OpenableDirective, OpenableExtendedDirective, ShoelaceModule };
