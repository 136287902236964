<core-form [data]="form">
    <core-validate>
        <sl-input
            autocompleteOff
            clearable
            size="small"
            name="search"
            [placeholder]="'_$.tableFilter.search.placeholder' | translate"
            (sl-input)="searchEssence()"
        >
            <sl-icon name="search" slot="prefix"></sl-icon>
        </sl-input>
    </core-validate>
</core-form>
