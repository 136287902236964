import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageEntity } from '../interfaces/storage';
import { StorageApi } from '../modules/rest/api-injectors';
import { ResponseType, RestService } from '../modules/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class StorageApiService {
    constructor(@Inject(StorageApi) private restService: RestService) {}

    resolve(path: string, version = '/v1'): string {
        return `${this.restService.baseUrl}${version}/storage/${path}`;
    }

    resolveStatic(path: string, version = '/v1'): string {
        return `${this.restService.baseUrl}${version}/${path}`;
    }

    download(path: string, filename?: string): Observable<void> {
        return this.restService.download(`/storage/${path}`, filename);
    }

    read(path: string): Observable<string> {
        return this.restService.get(
            `/storage/${path}`,
            {},
            { responseType: ResponseType.Text },
        );
    }

    getMeta(path: string): Observable<StorageEntity> {
        return this.restService.get(`/storage/${path}`);
    }
}
