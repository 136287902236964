import {
    AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    Renderer2,
} from '@angular/core';
import { SlTooltip } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import {
    distinctUntilChanged,
    filter,
    fromEvent,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { TOOLTIP_DELAY } from '../table-icon/tooltip-delay.token';

@Directive({
    selector: 'core-table',
})
export class TableTextOverflow
    extends SubscribableDirective
    implements AfterViewInit
{
    isShowTooltip = false;

    constructor(
        private host: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        @Inject(TOOLTIP_DELAY) public delay: number,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const table = this.host.nativeElement;

        const tooltip: SlTooltip = this.renderer.createElement('sl-tooltip');

        tooltip.setAttribute('hoist', 'true');
        tooltip.setAttribute('placement', 'top-start');

        const positioner: HTMLElement = this.renderer.createElement('div');
        positioner.style.position = 'fixed';

        this.renderer.appendChild(tooltip, positioner);
        this.renderer.insertBefore(table.parentElement, tooltip, table);

        this.subscriptions = [
            fromEvent<{ target?: HTMLElement }>(table, 'mouseover')
                .pipe(
                    filter(({ target }) => target?.tagName === 'TD'),
                    map(({ target }) => target!),
                    filter(
                        (td) =>
                            td.offsetWidth < td.scrollWidth &&
                            !td.classList.contains('notHover'),
                    ),
                    switchMap((td) => {
                        let { left, right, top, bottom } =
                            td.getBoundingClientRect();

                        const {
                            paddingLeft,
                            paddingRight,
                            paddingTop,
                            paddingBottom,
                        } = getComputedStyle(td);

                        left += parseFloat(paddingLeft);
                        right -= parseFloat(paddingRight);
                        top += parseFloat(paddingTop);
                        bottom -= parseFloat(paddingBottom);

                        return fromEvent(td, 'mousemove').pipe(
                            takeUntil<any>(
                                fromEvent(td, 'mouseleave').pipe(
                                    tap(() => {
                                        this.isShowTooltip = false;

                                        tooltip.hide();
                                    }),
                                ),
                            ),
                            map<MouseEvent, boolean>(({ x, y }) => {
                                return (
                                    x >= left &&
                                    x <= right &&
                                    y >= top &&
                                    y <= bottom
                                );
                            }),
                            distinctUntilChanged(),
                            tap((show) => {
                                if (show) {
                                    positioner.style.left = left + 'px';
                                    positioner.style.top = top + 'px';
                                    positioner.style.height =
                                        bottom - top + 'px';

                                    tooltip.content = td.textContent || '';

                                    this.isShowTooltip = true;

                                    this.showTooltipWithDelay(tooltip);
                                } else {
                                    tooltip.hide();
                                }
                            }),
                        );
                    }),
                )
                .subscribe(),
        ];
    }

    showTooltipWithDelay(tooltip: SlTooltip): void {
        setTimeout(() => {
            if (this.isShowTooltip) tooltip.show();
        }, this.delay);
    }
}
