<plmt-dropdown
    #dropdown
    [containingElement]="host"
    [distance]="2"
    [placement]="placement"
>
    <core-thead-trigger
        [class.hovered]="dropdown.isOpen"
        [type]="type"
        [direction]="direction"
    ></core-thead-trigger>

    <sl-menu>
        <ng-content></ng-content>

        <ng-container
            *ngIf="!sortComponent"
            [ngTemplateOutlet]="defaultTemplate"
        ></ng-container>
    </sl-menu>
</plmt-dropdown>

<ng-template #defaultTemplate>
    <core-table-sort routing [by]="by" [type]="type"></core-table-sort>
</ng-template>
