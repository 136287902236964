<ng-container *ngIf="dateValue">
    <sl-tooltip hoist [content]="dateValue | date">
        <ng-container *ngIf="isRelative; else dateTemplate">
            <sl-relative-time
                [date]="dateValue"
                [lang]="locale | async"
            ></sl-relative-time>
        </ng-container>

        <ng-template #dateTemplate>
            <sl-format-date
                month="numeric"
                day="numeric"
                year="numeric"
                hour="numeric"
                minute="numeric"
                hour-format="24"
                [second]="second"
                [date]="dateValue"
                [lang]="locale | async"
            ></sl-format-date>
        </ng-template>
    </sl-tooltip>
</ng-container>
