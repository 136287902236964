"use strict";
exports.__esModule = true;
exports.enumToArray = void 0;
function enumToArray(source) {
    var items = [];
    for (var key in source)
        if (isNaN(Number(key)))
            items.push(source[key]);
    return items;
}
exports.enumToArray = enumToArray;
