<label *ngIf="label">{{ label }}</label>

<sl-dropdown
    #slDropdown
    hoist
    [placement]="placement"
    [(open)]="showDropdown"
    [distance]="2"
    [availableHeight]="dropdownHeight"
    (sl-show)="setDropdownWidth()"
>
    <div
        slot="trigger"
        class="input"
        [attr.disabled]="disabled"
        (mousedown)="onInputMouseDown($event)"
    >
        <sl-input
            #slInput
            autocomplete="off"
            [attr.open]="showDropdown"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [size]="size"
            (sl-focus)="onInputFocus()"
            (sl-input)="onInput()"
            (keydown.arrowDown)="onFocusDropdownList()"
        >
            <core-select-suffix
                [dropdownTarget]="slDropdown"
                (onDropdown)="slInput.focus()"
            ></core-select-suffix>
        </sl-input>

        <div *ngIf="selected.length" class="selected">
            <plmt-tag
                *ngFor="let item of selected"
                variant="neutral"
                removable
                [size]="tagSize"
                (remove)="unselect(item)"
            >
                <core-overflow>
                    {{ getLabelFn(item) }}
                </core-overflow>

                <sl-icon-button
                    slot="remove-button"
                    library="core"
                    name="multi-select-tag-delete"
                ></sl-icon-button>
            </plmt-tag>
        </div>
    </div>

    <div
        class="listbox"
        [class.hidden]="suggestions.length === 0"
        [style.width]="dropdownWidth"
    >
        <sl-menu>
            <sl-menu-item
                *ngFor="let item of suggestions"
                tooltipify
                type="checkbox"
                [checked]="hasChecked(item)"
                (click)="toggle($event, item)"
            >
                {{ getLabelFn(item) }}
            </sl-menu-item>
        </sl-menu>

        <div
            class="spinner-container"
            [class.hidden]="!loading"
            [style.width]="dropdownWidth"
        >
            <sl-spinner></sl-spinner>
        </div>
    </div>
</sl-dropdown>
