import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { WatchChanges } from 'ng-onpush';
import { isArray } from 'lodash';
import { AccessRightsService } from '../../services/access-rights.service';

@Component({
    selector: 'core-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.less'],
})
export class EmptyStateComponent<T> implements OnInit {
    @Input()
    imageSrc!: string;

    @Input()
    set title(value: string) {
        if (value) this._title = value;
    }

    @Input()
    buttonText: string = '$.add';

    @Input()
    isNotSearch = true;

    @Input()
    showButton = true;

    @Input()
    currentAccessRight!: number | number[];

    @Output()
    onBtnClick = new EventEmitter<void>();

    @HostBinding('style.min-height')
    hostMinHeight = '';

    @ContentChild('description', { read: TemplateRef })
    templateDesc?: TemplateRef<{ $implicit: T }>;

    @ContentChild('button', { read: TemplateRef })
    templateButton?: TemplateRef<{ $implicit: T }>;

    @WatchChanges()
    _title = '_$.tableFilter.emptyStateFilter.title';

    @WatchChanges()
    accessToContent = true;

    constructor(
        private host: ElementRef<HTMLElement>,
        private accessRightsService: AccessRightsService,
    ) {}

    ngOnInit(): void {
        const { y } = this.host.nativeElement.getBoundingClientRect();
        this.hostMinHeight = `calc(100vh - ${y * 2}px)`;

        if (this.currentAccessRight) {
            this.accessToContent = this.checkAccessRights(
                this.currentAccessRight,
            );
        }
    }

    checkAccessRights(rights: number | number[]) {
        if (!isArray(rights)) rights = [rights];

        return this.accessRightsService.checkAccessRights(rights);
    }
}
