import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { TranslateModule } from '../../modules/translate/translate.module';

import { ActionDropdownComponent } from './action-dropdown.component';
import {
    PlmtButtonComponent,
    PlmtDropdownModule,
} from 'src/shared/plmt-ui-components';
import { NgForTrackByKeyDirective } from 'src/shared/directives/track-by-key.directive';

export * from './action-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        ShoelaceModule,
        TestIdDirectiveModule,
        TranslateModule,
        PlmtDropdownModule,
        PlmtButtonComponent,
        NgForTrackByKeyDirective,
    ],
    exports: [ActionDropdownComponent],
    declarations: [ActionDropdownComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActionDropdownModule {}
