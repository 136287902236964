<plmt-toggle size="small">
    <plmt-toggle-item
        [selected]="!onlySelf()"
        (onChooseToggle)="changeOnlySelf()"
    >
        {{ '_$.tableFilter.all' | translate }}
    </plmt-toggle-item>
    <plmt-toggle-item
        [selected]="onlySelf()"
        (onChooseToggle)="changeOnlySelf()"
    >
        {{ '_$.tableFilter.onlySelf' | translate }}
    </plmt-toggle-item>
</plmt-toggle>
