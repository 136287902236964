<sl-tooltip
    *ngIf="isTooltip"
    hoist
    style="--show-delay: {{ delay }}ms"
    distance="10"
    [content]="isTranslate ? (getTooltip! | translate) : getTooltip"
    [placement]="placement"
>
    <sl-icon-container [icon]="getIcon"></sl-icon-container>
</sl-tooltip>

<sl-icon-container *ngIf="!isTooltip" [icon]="getIcon"></sl-icon-container>
