<plmt-dropdown
    #dropdown
    hoist
    [distance]="2"
    [placement]="placement"
    [disabled]="!hasLeastOneAccess"
    [containingElement]="host"
>
    <plmt-button
        clear
        slot="trigger"
        variant="simple"
        prefixIcon="three-dots-vertical"
        size="small"
    >
    </plmt-button>
    <sl-menu>
        <sl-menu-item
            *ngFor="let item of actions; trackByKey: 'type'"
            class="action-item"
            [test-id]="getTestId(item.type)"
            [disabled]="!item.hasAccess"
            (click)="onChange(item)"
        >
            <div *ngIf="item.template; else defaultTemplate">
                <ng-container *ngTemplateOutlet="item.template"></ng-container>
            </div>
            <ng-template #defaultTemplate>
                {{ '$.' + item.type | translate }}
            </ng-template>
        </sl-menu-item>
    </sl-menu>
</plmt-dropdown>
