import {
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { SlDropdown } from '@shoelace-style/shoelace';

import { SubscribableDirective } from 'ngx-subscribable';

import { PlmtDropdownComponent } from './plmt-dropdown.component';

@Directive({
    selector: 'plmt-dropdown[open]',
})
export class PlmtDropdownOpenDirective
    extends SubscribableDirective
    implements OnInit, OnChanges
{
    @Input()
    open: boolean = false;

    @Output()
    openChange = new EventEmitter<boolean>();

    private get internalDropdown(): SlDropdown {
        return this.plmtDropdown.dropdownElement.nativeElement;
    }

    constructor(private plmtDropdown: PlmtDropdownComponent) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.plmtDropdown.slAfterHideEvent.subscribe(() =>
                this.changeOpen(false),
            ),
            this.plmtDropdown.slAfterShowEvent.subscribe(() =>
                this.changeOpen(true),
            ),
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.open) {
            this.internalDropdown.open = changes.open.currentValue;
        }
    }

    private changeOpen(value: boolean): void {
        this.open = value;

        this.openChange.emit(value);
    }
}
