import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import not from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ModuleName } from '../../enums/module';
import { AccessRightsService } from '../../services/access-rights.service';
import { AppService } from '../../services/app.service';
import { elementOnView } from '../../tools/element-on-view';
import { NotificationComponent } from '../navigation/notification/notification.component';
import {
    navigationConfig,
    NavigationItem,
    NavigationList,
} from './navigation-config';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';
import { NavigationService } from './navigation.service';

const WINDOW_MAX_SCREEN_HEIGHT = 840;
const SETTINGS_MODULE = 'nav.menu.settings';

@Component({
    selector: 'core-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent
    extends SubscribableComponent
    implements OnInit
{
    @ViewChild('container')
    container!: ElementRef<HTMLDivElement>;

    @ViewChild(NotificationComponent)
    notification!: NotificationComponent;

    @ViewChildren(NavigationItemComponent, { read: ElementRef<HTMLElement> })
    navItems!: ElementRef<HTMLElement>[];

    modules$ = new BehaviorSubject<NavigationList[]>([]);

    navigationMenu: NavigationItem[] = [];

    isShowMore = false;

    untouched = 0;

    private navigationConfig: NavigationList[] = navigationConfig;

    get burgerButton(): string {
        return this.isOpen ? 'x' : 'list';
    }

    get isOpen(): boolean {
        return this.navigationService.isOpenMenu.value;
    }

    get isDisplayMenu(): boolean {
        return this.navigationService.isDisplayMenu.value;
    }

    get settingsModuleIndex(): number {
        return this.navigationConfig.findIndex(
            (module) => module.label === SETTINGS_MODULE,
        );
    }

    constructor(
        private appService: AppService,
        private accessRightsService: AccessRightsService,
        private navigationService: NavigationService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (location.hash.includes('hidenavbar')) {
            this.navigationService.isDisplayMenu.next(false);

            return;
        }

        this.navigationMenu = this.createMenuItems([
            this.navigationConfig[this.settingsModuleIndex],
        ])[0].items;

        this.subscriptions = [
            fromEvent(window, 'resize')
                .pipe(debounceTime(100))
                .subscribe(() => {
                    this.createMenu();
                }),

            this.modules$.pipe(debounceTime(100)).subscribe(() => {
                if (this.isOpen) this.scrollTo();
            }),

            this.navigationService.isOpenMenu.subscribe(() => {
                this.createMenu();
            }),
        ];
    }

    toggleMenu(): void {
        this.navigationService.isOpenMenu.next(!this.isOpen);
    }

    private createMenu(): void {
        const viewport = window.innerHeight;

        this.navigationConfig = [...navigationConfig];

        if (viewport <= WINDOW_MAX_SCREEN_HEIGHT && not(this.isOpen)) {
            this.isShowMore = true;

            const idx = this.settingsModuleIndex;

            if (idx > -1) {
                this.navigationConfig.splice(idx, 1);
            }
        } else {
            this.navigationConfig = [...navigationConfig];

            this.isShowMore = false;
        }

        const modules = this.createMenuItems(this.navigationConfig);

        this.modules$.next(modules);

        const hasItems = this.modules$.value.some(({ items }) => items.length);

        this.navigationService.hasMenuItems.next(hasItems);
    }

    private createMenuItems(config: NavigationList[]): NavigationList[] {
        const modules: NavigationList[] = [];

        config.forEach((module) => {
            const items = module.items.filter((item) => {
                if ('superUserAccess' in item) {
                    return this.appService.isSuperUser;
                }

                const accessRights = Object.values(item.accessRights).flat();
                const moduleName = Object.keys(
                    item.accessRights,
                ).toString() as ModuleName;

                return this.accessRightsService.checkAccessRights(
                    accessRights,
                    moduleName,
                );
            });

            modules.push({
                label: module.label,
                items,
            });
        });

        return modules;
    }

    private scrollTo(): void {
        this.navItems.forEach((item) => {
            const host = item.nativeElement;
            const container = this.container!.nativeElement;

            const link = host.querySelector('a');

            if (link && link.classList.contains('activated')) {
                this.subscriptions.push(
                    elementOnView(host, container).subscribe((onView) => {
                        if (not(onView)) {
                            host.scrollIntoView({
                                behavior: 'smooth',
                            });
                        }
                    }),
                );
            }
        });
    }
}
