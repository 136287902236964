import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { map } from 'rxjs';

import { SlMenuItem } from '@shoelace-style/shoelace';
import { SortDirection } from '../../../enums/sort';
import { RequestArray } from '../../../interfaces/rest-api';
import { isNull } from 'lodash';
import { ColumnType } from '../../../enums/dataset';

export type SortState = Pick<RequestArray, 'order_by' | 'order_direction'>;

export type ColumnEntityType = 'string' | 'date' | 'number';

@Component({
    selector: 'core-table-sort:not([routing])',
    templateUrl: './table-sort.component.html',
    styleUrls: ['./table-sort.component.less'],
})
export class TableSortComponent implements OnChanges {
    @Input()
    by = '';

    @Input()
    direction?: SortDirection | null;

    @Input()
    set type(type: ColumnEntityType | ColumnType) {
        if (typeof type === 'string') {
            this.#type = type;

            return;
        }

        switch (type) {
            case ColumnType.Date: {
                this.#type = 'date';
                break;
            }

            case ColumnType.Number:
                this.#type = 'number';
        }
    }

    get type(): ColumnEntityType {
        return this.#type;
    }

    #type: ColumnEntityType = 'string';

    @Output()
    directionChange = new EventEmitter<SortDirection | null>();

    @Output()
    change = this.directionChange.pipe(
        map<SortDirection | null, SortState>((direction) => ({
            order_by: this.by,
            order_direction: direction,
        })),
    );

    @ViewChild('asc', { static: true })
    ascItem!: ElementRef<SlMenuItem>;

    @ViewChild('desc', { static: true })
    descItem!: ElementRef<SlMenuItem>;

    readonly SortDirection = SortDirection;

    private isChangeByClick = false;

    changeDirection(direction: SortDirection | null): void {
        this.direction = direction;

        this.isChangeByClick = true;

        this.directionChange.emit(direction);
    }

    ngOnChanges({ direction: { firstChange } }: SimpleChanges): void {
        if (isNull(this.direction) && !this.isChangeByClick) {
            this.ascItem.nativeElement.checked =
                this.descItem.nativeElement.checked = false;

            return;
        }

        this.isChangeByClick = false;

        if (!this.direction || !firstChange) return;

        const item =
            this.direction == SortDirection.Asc ? this.ascItem : this.descItem;

        item.nativeElement.checked = true;
    }
}
