import { Directive, Input, OnInit } from '@angular/core';
import { SubscribableDirective } from 'ngx-subscribable';
import { switchMap } from 'rxjs';

import { DataSearchProvider } from '../../../interfaces/rest-api';
import { InputComponent } from './input.component';

@Directive({ selector: 'core-input[search]' })
export class InputSearchDirective
    extends SubscribableDirective
    implements OnInit
{
    @Input()
    search!: DataSearchProvider<unknown>;

    constructor(private inputComponent: InputComponent) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.inputComponent.changeValue
                .pipe(switchMap(this.search))
                .subscribe(),
        ];
    }
}
