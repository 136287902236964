import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { not } from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';

import { getHost } from '../../../tools/get-host';
import {
    PopupPortalService,
    PortalPopupAgent,
} from '../popup-portal/popup-portal.service';
import { DropdownPlacement, DropdownTrigger } from './dropdown.interfaces';

const SHEVRON_INITIAL = 'keyboard_arrow_down';
const SHEVRON_OPEN = 'keyboard_arrow_up';

@Component({
    selector: 'core-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.less'],
})
export class DropdownComponent extends SubscribableComponent implements OnInit {
    @Input()
    button!: HTMLButtonElement;

    @Input()
    shouldSyncWidth: boolean = true;

    @Input()
    shevron?: HTMLElement;

    @Input()
    trigger: DropdownTrigger = 'click';

    @Input()
    placement: DropdownPlacement = 'bottom-start';

    @Input()
    visibleOverlay: boolean = true;

    @Output()
    show = new EventEmitter<void>();

    @Output()
    hide = new EventEmitter<void>();

    @Output()
    key = new EventEmitter<string>();

    zIndex = '';

    open = false;

    checkOutsideClick = false;

    @ViewChild('template', { static: true })
    private readonly template?: TemplateRef<{}>;

    private readonly host = getHost();

    constructor(private popupPortalService: PopupPortalService) {
        super();
    }

    ngOnInit(): void {
        this.setShevron(SHEVRON_INITIAL);

        const { button, shevron, show, hide, key, visibleOverlay } = this;

        const popupAgent: PortalPopupAgent = {
            hide,
            key,
            index: new EventEmitter(),
            visibleOverlay,
        };

        this.subscriptions = [
            show.subscribe(() => {
                if (not(this.template)) return;

                this.open = true;

                this.host.setAttribute('open', '');

                this.setShevron(SHEVRON_OPEN);

                this.popupPortalService.append(this.template, popupAgent);
            }),

            hide.subscribe(() => {
                if (not(this.template)) return;

                this.open = false;

                this.checkOutsideClick = false;

                this.host.removeAttribute('open');

                this.setShevron(SHEVRON_INITIAL);

                this.popupPortalService.remove(this.template);
            }),

            fromEvent<KeyboardEvent>(button, 'keydown').subscribe((event) => {
                if (this.open || event.key !== 'ArrowDown') return;

                event.stopPropagation();

                this.show.emit();
            }),

            popupAgent.index!.subscribe((value) => {
                this.zIndex = button.style.zIndex = String(value);
            }),
        ];

        if (this.trigger === 'click')
            this.subscriptions.push(
                fromEvent(button, 'click').subscribe((event) => {
                    if (this.open) event.stopPropagation();
                    else this.show.emit();
                }),
            );

        if (shevron)
            this.subscriptions.push(
                fromEvent(shevron, 'click').subscribe((event) => {
                    if (not(open)) return;

                    event.stopPropagation();

                    this.hide.emit();
                }),
            );
    }

    handleClickOutside(): void {
        if (this.open && !this.checkOutsideClick) {
            this.checkOutsideClick = true;
            return;
        }

        this.hide.emit();
    }

    private setShevron(value: string): void {
        if (this.shevron) this.shevron.textContent = value;
    }
}
