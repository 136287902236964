import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SubscribableComponent } from 'ngx-subscribable';
import { TableFilterItem, TableFilterType } from './table-filter.interfaces';
import { FormService } from '../../../modules/form/form.service';
import { stringValidator } from '../../../validators/string.validator';
import { Debounce } from 'plmt-core-library';

@Component({
    selector: 'core-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.less'],
})
export class TableFilterComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    config: TableFilterItem[] = [];

    //NOTE:
    //нужно для того что бы нажатие на enter было доступно только после анимации
    isShown = false;

    isApplied = false;

    readonly TableFilterType = TableFilterType;

    private restQueryParams: any = {};
    private configMap: any = {
        search: true,
    };

    form = this.formService.form<{
        search: string;
    }>({
        search: [null, [stringValidator({ min: -1, max: 250 })]],
    });

    constructor(
        private activatedRoute: ActivatedRoute,
        private formService: FormService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.activatedRoute.queryParams.subscribe((params) => {
                this.isApplied = false;

                Object.entries(params).forEach(([key, value]) => {
                    if (key in this.configMap) {
                        this.form.patchValue({ search: value });
                        this.isApplied = true;
                    } else {
                        this.restQueryParams[key] = value;
                    }
                });

                Object.keys(this.restQueryParams).forEach((key) => {
                    if (key in params) return;

                    delete this.restQueryParams[key];
                });
            }),
        ];
    }

    @Debounce(300)
    searchEssence(): void {
        if (this.form.invalid) {
            this.formService.showLocalErrors(this.form);

            return;
        }

        this.router.navigate([], {
            queryParams: {
                search: this.form.value.search || null,
                ...this.restQueryParams,
                offset: null,
            },
        });
    }
}
