import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { getHost } from 'src/shared/tools/get-host';

@Directive({
    selector: 'td[width], th[width]',
})
export class WidthDirective {
    @Input()
    @HostBinding('style.width')
    width = '';

    host = getHost<HTMLTableCellElement>();
}

@Directive({
    selector: 'td[maxWidth], th[maxWidth]',
})
export class MaxWidthDirective {
    @Input()
    @HostBinding('style.max-width')
    maxWidth = '';
}

@Directive({
    selector: 'td[minWidth]',
})
export class MinWidthDirective {
    @Input()
    @HostBinding('style.min-width')
    width = '';
}

@Directive({
    selector: 'th[minWidth]',
})
export class MinWidthThDirective {
    @Input('minWidth')
    width = '';

    host = getHost<HTMLTableCellElement>();
}
