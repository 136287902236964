import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { ThemeListResponse } from 'manager/src/app/api/theme-api.service';
import { Observable } from 'rxjs';

export interface Breadcrumbs {
    items: {
        label: Observable<string>;
        link: string;
    }[];

    target?: Observable<string>;
}

@Component({
    selector: 'core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent {
    @Input()
    title = '';

    @Input()
    counter = 0;

    @Input()
    currentTheme: ThemeListResponse | null = null;

    @HostBinding('class.has-breadcrumbs')
    @Input()
    breadcrumbs?: Breadcrumbs;

    @Input()
    globalTheme: boolean = false;

    @Input()
    extra?: TemplateRef<any>;

    @Input()
    checkedChoice: any[] = [];

    @Output()
    reset = new EventEmitter<void>();

    @Output()
    resetTheme = new EventEmitter<void>();
}
