import {
    AfterContentInit,
    ContentChildren,
    Directive,
    NgZone,
    OnInit,
    QueryList,
} from '@angular/core';
import { SubscribableDirective } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';
import { getHost } from 'src/shared/tools/get-host';
import { MinWidthThDirective, WidthDirective } from './width.directive';

const scrollbarWidth = 4;

@Directive({
    selector: 'core-table',
})
export class CalculateTableHeadWidthDirective
    extends SubscribableDirective
    implements AfterContentInit, OnInit
{
    @ContentChildren(WidthDirective, { descendants: true })
    staticTh!: QueryList<WidthDirective>;

    @ContentChildren(MinWidthThDirective, { descendants: true })
    dynamicTh!: QueryList<MinWidthThDirective>;

    private countDynamicTh = 0;

    private maxContentWidth = 0;

    private host = getHost();

    constructor(private ngZone: NgZone) {
        super();
    }

    ngAfterContentInit(): void {
        this.setDefaultThWidth();

        this.subscriptions.push(
            this.dynamicTh.changes.subscribe(() => {
                this.setDefaultThWidth();
            }),
        );
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.ngZone.runOutsideAngular(() =>
                fromEvent(window, 'resize').subscribe(() =>
                    this.calculateTableThWidth(),
                ),
            ),
        ];
    }

    private calculateTableThWidth(): void {
        const currentTableWidth = this.host.offsetWidth - scrollbarWidth;

        if (this.countDynamicTh === 0) return;

        const diff = currentTableWidth - this.maxContentWidth;

        const additionalWidth = diff < 0 ? 0 : diff / this.countDynamicTh;

        this.dynamicTh.forEach((th) => {
            th.host.style.width = pxToNumber(th.width) + additionalWidth + 'px';
        });
    }

    private setDefaultThWidth(): void {
        this.maxContentWidth = 0;
        this.countDynamicTh = this.dynamicTh.length;

        [...this.staticTh.toArray(), ...this.dynamicTh.toArray()].forEach(
            (directive) => {
                directive.host.style.width = directive.width;

                this.maxContentWidth += pxToNumber(directive.width);
            },
        );

        this.calculateTableThWidth();
    }
}

function pxToNumber(px: string): number {
    return Number(px.replace('px', ''));
}
