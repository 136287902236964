import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
const shared = {
  changeDetectorRef: null
};
class ChangeDetectionService {
  provideRootChangeDetectorRef(changeDetectorRef) {
    shared.changeDetectorRef = changeDetectorRef;
  }
  markForCheck() {
    var _a;
    (_a = shared.changeDetectorRef) === null || _a === void 0 ? void 0 : _a.markForCheck();
  }
}
ChangeDetectionService.ɵfac = function ChangeDetectionService_Factory(t) {
  return new (t || ChangeDetectionService)();
};
ChangeDetectionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ChangeDetectionService,
  factory: ChangeDetectionService.ɵfac,
  providedIn: "root"
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChangeDetectionService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], null, null);
})();
function UpdateImmediately() {
  return function (target) {
    const key = Symbol();
    const afterViewInitOrigin = target.ngAfterViewInit;
    let ready = false;
    target.ngAfterViewInit = function () {
      ready = true;
      if (afterViewInitOrigin) afterViewInitOrigin.apply(this, arguments);
    };
    return {
      get() {
        return this[key];
      },
      set(nextValue) {
        var _a;
        this[key] = nextValue;
        if (ready) (_a = shared.changeDetectorRef) === null || _a === void 0 ? void 0 : _a.detectChanges();
      }
    };
  };
}
function WatchChanges() {
  return function () {
    const key = Symbol();
    return {
      get() {
        return this[key];
      },
      set(nextValue) {
        var _a;
        this[key] = nextValue;
        (_a = shared.changeDetectorRef) === null || _a === void 0 ? void 0 : _a.markForCheck();
      }
    };
  };
}

/*
 * Public API Surface of ng-onpush
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ChangeDetectionService, UpdateImmediately, WatchChanges };
