import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscribableComponent } from 'ngx-subscribable';
import { AppService } from 'plmt-core-library';

@Component({
    selector: 'app-table-self-checkbox-filter',
    templateUrl: './table-self-checkbox-filter.component.html',
    styleUrls: ['./table-self-checkbox-filter.component.less'],
})
export class TableSelfCheckboxFilterComponent
    extends SubscribableComponent
    implements OnInit
{
    onlySelf: WritableSignal<boolean> = signal(false);

    constructor(
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions = [
            this.activatedRoute.queryParams.subscribe(() => {
                this.checkOnlySelf();
            }),
        ];
    }

    changeOnlySelf(): void {
        this.router.navigate([], {
            queryParams: {
                ...this.activatedRoute.snapshot.queryParams,
                owners: !this.onlySelf()
                    ? [this.appService.user.value.id]
                    : null,
                offset: null,
            },
        });
    }

    private checkOnlySelf(): void {
        const currentUserId = this.appService.user.value.id;
        const { owners } = this.activatedRoute.snapshot.queryParams;
        const arrayCondition =
            Array.isArray(owners) &&
            owners.length === 1 &&
            owners[0] === currentUserId;

        const userIdCondition = Number(owners) === currentUserId;

        if (arrayCondition || userIdCondition) {
            this.onlySelf.set(true);

            return;
        }

        this.onlySelf.set(false);
    }
}
