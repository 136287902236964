import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IconInput } from '../../interfaces/sl-icon.type';
import { SlButtonSize, SlButtonVariant } from './model';
import { IconContainerComponent } from '../../components/sl-icon-container/sl-icon-container.component';
import { NgIf } from '@angular/common';
/**
 * Данный компонент PlmtButtonComponent используется для создания кнопок с различным дизайном,
 * определяемым свойствами variant, size, loading, pill, circle, light, clear и outline
 *
 * Каждое входное свойство модифицирует внешний вид и поведение кнопки:
 * @component
 * @example
 * `<plmt-button  [variant]="primary" [size]="medium" pill light></plmt-button>`
 *
 * @property {boolean} disabled - если true, делает кнопку неактивной
 * @property {IconInput} prefixIcon - отображает иконку в начале
 * @property {IconInput} suffixIcon - отображает иконку в конце
 * @property {SlButtonVariant} variant - определяет внешний вид кнопки
 * @property {SlButtonSize} size - определяет размер кнопки
 * @property {boolean} loading - если true, отображает индикатор загрузки
 * @property  pill - если определено, делает закругление
 * @property  circle - если определено, делает кнопку круглой
 * @property  light - если определено, делает светлую тему
 * @property  clear - если определено, делает фон кнопки прозрачным
 * @property  outline - если определено, добавляет кнопке контур (border)
 *
 * **Примечание**
 *
 * Если в `ng-content` передать просто `sl-icon` то паддинги будут по 10px
 */
@Component({
    selector: 'plmt-button',
    standalone: true,
    templateUrl: './plmt-button.component.html',
    styleUrls: ['./plmt-button.component.less'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [NgIf, IconContainerComponent],
})
export class PlmtButtonComponent {
    @Input()
    disabled: boolean = false;

    @Input()
    prefixIcon?: IconInput;

    @Input()
    suffixIcon?: IconInput;

    @Input()
    variant: SlButtonVariant = 'primary';

    @Input()
    size: SlButtonSize = 'medium';

    @Input()
    loading?: boolean;

    @Input()
    type?: string;

    @Input()
    pill: '' | undefined = void 0;

    @Input()
    circle: '' | undefined = void 0;

    @Input()
    light: '' | undefined = void 0;

    @Input()
    clear: '' | undefined = void 0;

    @Input()
    outline?: '' | undefined = void 0;

    @Input()
    slot?: string;

    @Input()
    caret?: '' | undefined = void 0;

    @Output()
    clicked = new EventEmitter<MouseEvent>();
}
