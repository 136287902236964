<ng-container [ngSwitch]="type">
    <sl-input
        *ngSwitchCase="UIType.Text"
        [label]="'_$$.filters.label.value' | translate"
        [value]="value"
        (sl-input)="emitChange($event)"
    ></sl-input>

    <sl-input
        *ngSwitchCase="UIType.Number"
        type="number"
        [label]="'_$$.filters.label.value' | translate"
        [value]="value"
        (sl-input)="emitChange($event)"
    ></sl-input>

    <ng-container *ngSwitchCase="UIType.NumberRange">
        <sl-input
            type="number"
            [label]="'_$$.filters.label.value.numberRange.from' | translate"
            [value]="value?.[0]"
            (sl-input)="emitChangeRange($event, 0)"
        ></sl-input>

        <sl-input
            type="number"
            [label]="'_$$.filters.label.value.numberRange.to' | translate"
            [value]="value?.[1]"
            (sl-input)="emitChangeRange($event, 1)"
        ></sl-input>
    </ng-container>

    <plmt-filter-date-value-default
        *ngSwitchCase="UIType.Date"
        [label]="'_$$.filters.label.value' | translate"
        [value]="value"
        (change)="emitChange($event)"
        (changeOffset)="emitChangeDate($event)"
    ></plmt-filter-date-value-default>

    <div *ngSwitchCase="UIType.DateRange" class="range">
        <plmt-filter-date-value-default
            [label]="'_$$.filters.label.value' | translate"
            [value]="value?.[0]"
            (change)="emitChangeRange($event, 0)"
            (changeOffset)="emitChangeDateRange($event, 0)"
        ></plmt-filter-date-value-default>

        <plmt-filter-date-value-default
            [value]="value?.[1]"
            [validationError]="validationError"
            (change)="emitChangeRange($event, 1)"
            (changeOffset)="emitChangeDateRange($event, 1)"
        ></plmt-filter-date-value-default>
    </div>

    <plmt-filter-date-value-default
        *ngSwitchCase="UIType.DateTime"
        [label]="'_$$.filters.label.value' | translate"
        [dateFormat]="dateFormat"
        [value]="value"
        (change)="emitChange($event)"
        (changeOffset)="emitChangeDate($event)"
    ></plmt-filter-date-value-default>

    <div *ngSwitchCase="UIType.DateTimeRange" class="range">
        <plmt-filter-date-value-default
            [label]="'_$$.filters.label.value' | translate"
            [dateFormat]="dateFormat"
            [value]="value?.[0]"
            (change)="emitChangeRange($event, 0)"
            (changeOffset)="emitChangeDateRange($event, 0)"
        ></plmt-filter-date-value-default>

        <plmt-filter-date-value-default
            [dateFormat]="dateFormat"
            [value]="value?.[1]"
            [validationError]="validationError"
            (change)="emitChangeRange($event, 1)"
            (changeOffset)="emitChangeDateRange($event, 1)"
        ></plmt-filter-date-value-default>
    </div>

    <plmt-select
        *ngSwitchCase="UIType.Select"
        hoist
        clearable
        [label]="'_$$.filters.label.value' | translate"
        [value]="value"
        [disabled]="!defaultValues.length"
        (sl-change)="emitChange($event)"
    >
        <sl-option *ngFor="let item of defaultValues" [value]="item">
            {{ item }}
        </sl-option>
    </plmt-select>

    <core-multi-select
        *ngSwitchCase="UIType.SelectMulti"
        [label]="'_$$.filters.label.value' | translate"
        [selected]="toArray(value)"
        [getLabelFn]="getLabelFn"
        [placement]="defaultValues.length > 1 ? 'top-start' : 'bottom-start'"
        [getValueFn]="getValueFn"
        [suggestions]="defaultValues"
        [disabled]="!defaultValues.length"
        (onChange)="emitChange($any($event))"
    ></core-multi-select>
</ng-container>
