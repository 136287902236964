import { NgModule } from '@angular/core';
import { TranslateModule } from '../../modules/translate/translate.module';

import { EmptyStateComponent } from './empty-state.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { PlmtButtonComponent } from '../../plmt-ui-components';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { AccessRightsDirectiveModule } from '../../directives/access-rights.directive';

export * from './empty-state.component';

@NgModule({
    declarations: [EmptyStateComponent],
    exports: [EmptyStateComponent],
    imports: [
        TranslateModule,
        NgTemplateOutlet,
        PlmtButtonComponent,
        NgIf,
        TestIdDirectiveModule,
        AccessRightsDirectiveModule,
    ],
})
export class EmptyStateModule {}
