<div>
    <img [src]="imageSrc" />
    <h1>{{ _title | translate }}</h1>

    <ng-container *ngIf="isNotSearch && accessToContent">
        <p>
            <ng-template
                [ngTemplateOutlet]="templateDesc || defaultDescTemplate"
            ></ng-template>
        </p>

        <ng-template
            [ngTemplateOutlet]="templateButton || defaultButtonTemplate"
        ></ng-template>
    </ng-container>

    <ng-content></ng-content>
</div>

<ng-template #defaultDescTemplate>
    {{ 'emptyState.description' | translate }}
</ng-template>

<ng-template #defaultButtonTemplate>
    <plmt-button
        *ngIf="showButton"
        variant="primary"
        size="small"
        test-id="Add"
        [prefixIcon]="'plus-lg'"
        (clicked)="onBtnClick.emit()"
    >
        {{ buttonText | translate }}
    </plmt-button>
</ng-template>
