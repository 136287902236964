<p>{{ '$.sort' | translate }}</p>

<sl-menu-item
    #asc
    type="checkbox"
    (click)="
        changeDirection(asc.checked ? null : SortDirection.Asc);
        desc.checked = false
    "
>
    <sl-icon
        slot="prefix"
        [name]="type === 'string' ? 'sort-alpha-up-alt' : 'sort-up'"
    ></sl-icon>

    {{ (type === 'date' ? '$.sort-old' : '$.sort-asc') | translate }}
</sl-menu-item>

<sl-menu-item
    #desc
    type="checkbox"
    (click)="
        changeDirection(desc.checked ? null : SortDirection.Desc);
        asc.checked = false
    "
>
    <sl-icon
        slot="prefix"
        [name]="type === 'string' ? 'sort-alpha-down-alt' : 'sort-down'"
    ></sl-icon>

    {{ (type === 'date' ? '$.sort-new' : '$.sort-desc') | translate }}
</sl-menu-item>
