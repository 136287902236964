import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { SlDropdown, SlInput, SlTag } from '@shoelace-style/shoelace';
import { not } from 'logical-not';

import {
    CompareFn,
    GetLabel,
    GetValue,
} from '../../interfaces/items-operations';
import { Debounce } from '../../tools/debounce';

export type MultiSelectItem = any;

const DEFAULT_DROPDOWN_HEIGHT = 180;

@Component({
    selector: 'core-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.less'],
})
export class MultiSelectComponent implements AfterViewInit {
    @Input()
    selected: MultiSelectItem[] = [];

    @Input()
    suggestions: MultiSelectItem[] = [];

    @Input()
    label = '';

    @Input()
    loading = false;

    @Input()
    placeholder = '';

    @Input()
    disabled = false;

    @Input()
    tagSize: SlTag['size'] = 'small';

    @Input()
    size: SlInput['size'] = 'medium';

    @Input()
    placement: SlDropdown['placement'] = 'bottom-start';

    @Input()
    viewKey = 'name';

    @Input()
    uniqKey = 'id';

    @Input()
    getLabelFn: GetLabel<MultiSelectItem> = (item) => item[this.viewKey];

    @Input()
    getValueFn: GetValue<MultiSelectItem, any> = (item) => item[this.uniqKey];

    @Input()
    compareFn: CompareFn<MultiSelectItem> = (a, b) =>
        this.getValueFn(a) === this.getValueFn(b);

    @Input()
    cache = true;

    @Input()
    dropdownHeight = DEFAULT_DROPDOWN_HEIGHT;

    @Output()
    onChange = new EventEmitter<MultiSelectItem[]>();

    @Output()
    onSearch = new EventEmitter<string>();

    @ViewChild('slDropdown', { static: true })
    slDropdown!: ElementRef<SlDropdown>;

    @ViewChild(SlDropdown, { static: true })
    dropdown!: SlDropdown;

    @ViewChild('slInput', { static: true })
    slInput!: ElementRef<SlInput>;

    showDropdown = false;

    dropdownWidth = '0px';

    private touched = false;

    ngAfterViewInit(): void {
        const slInputElement = this.slInput.nativeElement;
        const slClosableElement = slInputElement.closest(
            'sl-dialog, sl-drawer',
        );

        slClosableElement?.addEventListener('sl-after-hide', (event) => {
            if (event.target === slClosableElement) {
                slInputElement.value = '';

                this.suggestions = [];
                this.touched = false;
            }
        });
    }

    @Debounce(100)
    onInputFocus(): void {
        this.showDropdown = true;

        if (not(this.cache)) {
            this.suggestions = [];
            this.onSearch.emit('');
        } else if (not(this.touched) && this.suggestions.length === 0)
            this.onSearch.emit('');
    }

    hasChecked(item: MultiSelectItem): boolean {
        const { compareFn } = this;

        return this.selected.some((selectedItem) =>
            compareFn(item, selectedItem),
        );
    }

    toggle(event: Event, item: MultiSelectItem): void {
        event.stopPropagation();

        if (this.hasChecked(item)) this.unselect(item);
        else this.select(item);
    }

    select(item: MultiSelectItem): void {
        this.onChange.emit([...this.selected, item]);

        this.afterSelectChange();
    }

    unselect(item: MultiSelectItem): void {
        if (this.disabled) return;

        const { selected, compareFn } = this;

        const i = selected.findIndex((selectedItem) =>
            compareFn(item, selectedItem),
        );

        if (i !== -1) {
            const nextValue = [
                ...selected.slice(0, i),
                ...selected.slice(i + 1),
            ];

            this.onChange.emit(nextValue);

            this.afterSelectChange();
        }
    }

    onFocusDropdownList(): void {
        const item =
            this.slDropdown.nativeElement.querySelector('sl-menu-item');

        item?.focus();
    }

    @Debounce(300)
    onInput(): void {
        this.touched = true;

        this.emitSearchEvent();
    }

    onInputMouseDown(event: Event) {
        if (this.showDropdown) event.stopPropagation();
    }

    setDropdownWidth(): void {
        const { width } = this.slInput.nativeElement.getBoundingClientRect();

        this.dropdownWidth = `${width}px`;
    }

    @HostListener('sl-select', ['$event'])
    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    @Debounce(300)
    private emitSearchEvent(): void {
        const { value } = this.slInput.nativeElement;

        this.onSearch.emit(value);
    }

    private afterSelectChange(): void {
        this.slInput.nativeElement.focus();

        setTimeout(() => {
            this.slDropdown.nativeElement.reposition();
        });
    }
}
