import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    Inject,
    Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICON_TOKEN, IconTokenType } from './table-icon.token';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { SlTooltip } from '@shoelace-style/shoelace';
import { TOOLTIP_DELAY } from './tooltip-delay.token';
import { IconInput } from 'src/shared/interfaces/sl-icon.type';
import { IconContainerComponent } from '../../sl-icon-container/sl-icon-container.component';

export * from './table-icon.token';
export * from './table-icon.token';

@Component({
    selector: 'table-icon',
    standalone: true,
    templateUrl: './table-icon.component.html',
    styleUrls: ['./table-icon.component.less'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule, TranslateModule, IconContainerComponent],
})
export class TableIconComponent<T> {
    @Input()
    row!: T;

    @Input()
    indexOfTokenValue: number = 0;

    @Input()
    placement: SlTooltip['placement'] = 'right';

    @Input()
    isTooltip: boolean = true;

    constructor(
        @Inject(ICON_TOKEN)
        private getSettings: IconTokenType<T> | IconTokenType<T>[],
        @Inject(TOOLTIP_DELAY) public delay: number,
    ) {}

    get getIcon(): IconInput {
        return this.getTokenFn()(this.row).icon;
    }
    get getTooltip(): string | undefined {
        return this.getTokenFn()(this.row).tooltip;
    }
    get isTranslate(): boolean | undefined {
        return this.getTokenFn()(this.row).isTranslate;
    }

    getTokenFn(): IconTokenType<T> {
        return Array.isArray(this.getSettings)
            ? this.getSettings[this.indexOfTokenValue]
            : this.getSettings;
    }
}
