<ng-container *ngIf="!checkedRows.length">
    <plmt-button
        *accessRights="accessRightsModule"
        variant="primary"
        size="small"
        test-id="Add"
        prefixIcon="plus-lg"
        (click)="clickedAdd.emit()"
    >
        {{ '$.add' | translate }}
    </plmt-button>
</ng-container>

<plmt-button
    *ngIf="checkedRows.length"
    clear
    variant="danger"
    test-id="Delete"
    prefixIcon="trash"
    size="small"
    (click)="clickedDelete.emit()"
>
    {{ '$.delete' | translate }}
</plmt-button>
