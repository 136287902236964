import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlmtButtonComponent } from 'src/shared/plmt-ui-components';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { ModuleRightsManager } from 'manager/src/app/enums/module-rights-manager';
import { AccessRightsDirectiveModule } from 'src/shared/directives/access-rights.directive';
import { ModuleRightsDashboard } from 'dashboard/src/app/enums/module-rights-dashboard';

@Component({
    selector: 'core-entity-action-buttons',
    standalone: true,
    imports: [
        CommonModule,
        PlmtButtonComponent,
        TranslateModule,
        AccessRightsDirectiveModule,
    ],
    templateUrl: './entity-action-buttons.component.html',
    styleUrls: ['./entity-action-buttons.component.less'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EntityActionButtonsComponent {
    @Input()
    checkedRows: any[] = [];

    @Input()
    accessRightsModule!: ModuleRightsManager | ModuleRightsDashboard;

    @Output()
    clickedAdd = new EventEmitter<void>();

    @Output()
    clickedDelete = new EventEmitter<void>();
}
