import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, NgModule, Input } from '@angular/core';
import { not } from 'logical-not';
function changeTrackBy(ngForOf, trackBy, property) {
  ngForOf.ngForTrackBy = trackBy;
  patch(ngForOf, trackBy, property);
}
function patch(ngForOf, trackBy, property) {
  const differ = ngForOf._differ;
  differ._trackByFn = trackBy;
  const linkedRecordsMap = differ._linkedRecords.map;
  differ.forEachItem(record => {
    const trackById = record.item[property];
    linkedRecordsMap.set(trackById, linkedRecordsMap.get(record.trackById));
    linkedRecordsMap.delete(record.trackById);
    record.trackById = record.item[property];
  });
}
const markedKey = Symbol();
function marked(ngForOf) {
  const marked = (markedKey in ngForOf);
  ngForOf[markedKey] = undefined;
  return marked;
}
const trackById = (_, item) => {
  return item.id;
};
class TrackByIdDirective {
  constructor(ngForOf) {
    this.ngForOf = ngForOf;
  }
  ngOnInit() {
    if (not(marked(this.ngForOf))) changeTrackBy(this.ngForOf, trackById, "id");
  }
}
TrackByIdDirective.ɵfac = function TrackByIdDirective_Factory(t) {
  return new (t || TrackByIdDirective)(i0.ɵɵdirectiveInject(i1.NgForOf));
};
TrackByIdDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TrackByIdDirective,
  selectors: [["", "trackById", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TrackByIdDirective, [{
    type: Directive,
    args: [{
      selector: "[trackById]"
    }]
  }], function () {
    return [{
      type: i1.NgForOf
    }];
  }, null);
})();
class TrackByIdModule {}
TrackByIdModule.ɵfac = function TrackByIdModule_Factory(t) {
  return new (t || TrackByIdModule)();
};
TrackByIdModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TrackByIdModule
});
TrackByIdModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TrackByIdModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [TrackByIdDirective],
      declarations: [TrackByIdDirective]
    }]
  }], null, null);
})();
class TrackByDirective {
  constructor(ngForOf) {
    this.ngForOf = ngForOf;
  }
  ngOnInit() {
    if (not(marked(this.ngForOf))) changeTrackBy(this.ngForOf, this.createTrackByFn(), this.trackBy);
  }
  createTrackByFn() {
    const key = this.trackBy;
    return function (_, item) {
      return item[key];
    };
  }
}
TrackByDirective.ɵfac = function TrackByDirective_Factory(t) {
  return new (t || TrackByDirective)(i0.ɵɵdirectiveInject(i1.NgForOf));
};
TrackByDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TrackByDirective,
  selectors: [["", "trackBy", ""]],
  inputs: {
    trackBy: "trackBy"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TrackByDirective, [{
    type: Directive,
    args: [{
      selector: "[trackBy]"
    }]
  }], function () {
    return [{
      type: i1.NgForOf
    }];
  }, {
    trackBy: [{
      type: Input
    }]
  });
})();
class TrackByModule {}
TrackByModule.ɵfac = function TrackByModule_Factory(t) {
  return new (t || TrackByModule)();
};
TrackByModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TrackByModule
});
TrackByModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TrackByModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [TrackByDirective],
      declarations: [TrackByDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-track-by
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TrackByDirective, TrackByIdDirective, TrackByIdModule, TrackByModule };
